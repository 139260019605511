import React, { useEffect, useState } from "react";
import CustomTab from "../CustomTab";
import UserInfo from "./UserInfo";
import AddUser from "./AddUser";
import { userFields } from "../../models";
import { useBookProvider } from "../BookContext";

export default function User() {
  const { readOnly, roles } = useBookProvider();
  const [tabs, setTabs] = useState([]);
  const [tab, setTab] = React.useState(0);

  useEffect(() => {
    const mappedTabs = roles.map((item, index) => {
      const fields =
        item?.title === "student"
          ? userFields.student
          : item?.title === "other"
          ? userFields.all
          : userFields.teacher;

      return {
        title: item?.title,
        index: index + 1,
        component: () => <UserInfo roleId={item.id} userFields={fields} />,
      };
    });

    const initTabs = {
      title: "All User",
      index: 0,
      component: () => <UserInfo roleId="" userFields={userFields.all} />,
    };

    const addTab = {
      title: "Add User",
      index: roles.length + 1,
      component: () => <AddUser handleTabSwitch={handleTabSwitch} />,
    };

    mappedTabs.unshift(initTabs);
    if (!readOnly) mappedTabs.push(addTab);

    setTabs(mappedTabs);
  }, [roles, readOnly]);

  const handleTabSwitch = (tabId) => setTab(tabId);

  return (
    <CustomTab defaultTab={tab} tabs={tabs} handleTabChange={handleTabSwitch} />
  );
}
