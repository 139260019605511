import * as React from "react";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import { Link, Outlet } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { useBookProvider } from "../BookContext";
import { commonNavItems, secretNavItems } from "../../constants/NavItems";
import { Toolbar } from "@mui/material";
import { background , iconColor} from "../../constants/parameters";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  backgroundColor: background,
  color: iconColor,
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  backgroundColor: background,
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function CustomDrawer(props) {
  const [open, setOpen] = React.useState(true);
  const [navs, setNavs] = React.useState(commonNavItems);
  const { readOnly } = useBookProvider();

  React.useEffect(() => {
    if (!readOnly) setNavs(commonNavItems.concat(secretNavItems));
  }, [readOnly]);

  //  maxWidth: "calc(100vw - 305px)"

  return (
    <>
      <Drawer variant="permanent" open={open}>
        <Toolbar sx={{ minHeight: "50px !important" }} />
        <IconButton
          sx={{ color: iconColor, borderRadius: 0 }}
          onClick={() => setOpen(!open)}
        >
          {!open && <KeyboardDoubleArrowRightIcon />}
          {open && <KeyboardDoubleArrowLeftIcon />}
        </IconButton>
        <Divider />

        <List sx={{ pt: 0 }}>
          {navs.map((item, index) => (
            <Link
              to={item.route}
              key={index}
              style={{ color: iconColor, textDecoration: "none" }}
            >
              <ListItem key={index} disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  sx={{
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    {item.icon()}
                  </ListItemIcon>
                  <ListItemText
                    primary={item.title}
                    disableTypography={true}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
                <Divider />
              </ListItem>
            </Link>
          ))}
        </List>
      </Drawer>
      <Box
        component="main"
        sx={{ width: "calc(100% - 240px)", flexGrow: 1, p: 3 }}
      >
        <Toolbar sx={{ minHeight: "40px !important" }} />
        <Outlet />
      </Box>
    </>
  );
}
