import { apiInstance, processApiResponse } from "./parent";
const UserAPI = {
  async getUsers() {
    const path = "/user/all";
    const response = await apiInstance.get(path);
    return processApiResponse(response);
  },

  async getUser(id) {
    const path = "/user/" + id;
    const response = await apiInstance.get(path);
    return processApiResponse(response);
  },

  async addUser(data) {
    const path = "/user";
    const response = await apiInstance.post(path, data);
    return processApiResponse(response);
  },

  async updateUser(data) {
    const path = "/user/update";
    const response = await apiInstance.post(path, data);
    return processApiResponse(response);
  },

  async addRole(data) {
    const path = "/user/role";
    const response = await apiInstance.post(path, data);
    return processApiResponse(response);
  },

  async getRoles() {
    const path = "/user/roles";
    const response = await apiInstance.get(path);
    return processApiResponse(response);
  },

  async updatePassword(data) {
    const path = "/user/update/password";
    const response = await apiInstance.post(path, data);
    return processApiResponse(response);
  },

  async removeUser(id) {
    const path = "/user/remove/" + id;
    const response = await apiInstance.get(path);
    return processApiResponse(response);
  },
};

export default UserAPI;
