import * as React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { experimentalStyled as styled } from "@mui/material/styles";
import { useBookProvider } from "../../BookContext";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  cursor: "pointer",
  ":hover": {
    color: "green",
    textDecoration: "underline",
  },
}));

export default function BookTypes(props) {
  const { books, bookTypes } = useBookProvider();
  const { handleTabSwitch } = props;
  const [types, setTypes] = React.useState([]);

  const handleClick = (typeId) => (event) => {
    handleTabSwitch(0, typeId);
  };

  React.useEffect(() => {
    const mappedTypes = bookTypes.map((item) => ({
      id: item.id,
      title: item.title,
      total: books.filter(
        (book) => book.typeId === item.id && !book.written_off
      )?.length,
    }));
    setTypes(mappedTypes);
  }, [bookTypes, books]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid
        container
        spacing={{ xs: 1, md: 2 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
        {types.map((type, index) => (
          <Grid item xs={2} sm={4} md={4} key={index}>
            <Item elevation={2} onClick={handleClick(type.id)}>
              {type.title}({type.total})
            </Item>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
