import jsPDF from "jspdf";
import "jspdf-autotable";

const recordPerRow = 8;

const bookIdGenerator = (records, title) => {
  var doc = new jsPDF();
  doc.text("Library Management System(RCC)", 14, 16);
  doc.setFontSize(11);
  doc.setTextColor(100);
  doc.text(title, 14, 22);
  records.forEach((record) => {
    doc.autoTable({
      head: mapHeader(record.title, record?.total),
      body: record.data,
      startY: (doc.lastAutoTable.finalY || 10) + 15,
      showHead: "firstPage",
      theme: "grid",
      columnStyles: columnStyles,
    });
  });

  doc.save(`${title}_report.pdf`);
};

const columnStyles = {
  0: {
    halign: "center",
  },
  1: {
    halign: "center",
  },
  2: {
    halign: "center",
  },
  3: {
    halign: "center",
  },
  4: {
    halign: "center",
  },
  5: {
    halign: "center",
  },
};

const mapHeader = (title, numOfBook) => {
  const head = [
    [
      {
        content: title + "(" + numOfBook + ")",
        colSpan: recordPerRow,
        styles: { halign: "center", fillColor: [22, 160, 133] },
      },
    ],
  ];
  return head;
};

export default bookIdGenerator;
