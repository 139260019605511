const bookFields = [
  {
    id: "book_id",
    numeric: true,
    disablePadding: false,
    label: "Book Id",
    width: "80px",
  },
  {
    id: "type_name",
    numeric: false,
    disablePadding: false,
    label: "Type",
    width: "100px",
  },
  {
    id: "title",
    numeric: false,
    disablePadding: false,
    label: "Title",
    width: "200px",
  }, 
  {
    id: "author",
    numeric: false,
    disablePadding: false,
    label: "Author",
    width: "200px",
  }, 
  {
    id: "publisher",
    numeric: false,
    disablePadding: false,
    label: "Publisher",
    width: "200px",
  },
  {
    id: "publication_year",
    numeric: false,
    disablePadding: false,
    label: "Publication Year",
    width: "auto",
  },
  {
    id: "issued_name",
    numeric: false,
    disablePadding: true,
    label: "Issued To",
    width: "auto",
  },
  {
    id: "edition",
    numeric: false,
    disablePadding: false,
    label: "Edition",
    width: "auto",
  },
  {
    id: "shelf_no",
    numeric: false,
    disablePadding: false,
    label: "Shelf Number",
    width: "auto",
  },
  {
    id: "price",
    numeric: false,
    disablePadding: false,
    label: "Price",
    width: "auto",
  },
  {
    id: "pages",
    numeric: false,
    disablePadding: false,
    label: "Pages",
    width: "auto",
  },
  {
    id: "note",
    numeric: false,
    disablePadding: false,
    label: "Note",
    width: "auto",
  },  
  {
    id: "createdAt",
    numeric: false,
    disablePadding: false,
    label: "Entry Date",
    width: "auto",
    type: "date"
  },
];

export default bookFields;
