export const ERROR = "error";
export const ALERT = "alert";
export const SUCCESS = "success";
export const USER_ID = "user-id";
export const LOADING = "loading";
export const ISSUE_BOOKS = "issue-books";
export const RENEW_BOOKS = "renew-books";
export const RETURN_BOOKS = "return-books";
export const ADD_USER = "add-books";
export const EDIT_USER = "edit-books";
export const REMOVE_USER = "remove-user";
export const ADD_ROLE = "add-role";
export const ADD_TYPE = "add-type";
export const ADD_BOOK = "add-book";
export const REMOVE_BOOK = "remove-book";
export const UPDATE_BOOK = "update-book";
export const ROLLBACK_BOOKS = "rollback-books";
export const REMOVE_ISSUED_BOOK = "remove-issued-book";
export const UPDATE_EBOOK = "update-ebook";
export const ADD_EBOOK = "add-ebook";
export const REMOVE_EBOOK = "remove-ebook";
export const DELETE_BOOK = "delete-ebook";
