const utils = {
  mapBooks(books) {
    return books.map((book) => ({
      ...book,
      type_name: book.type?.title,
      issued_user_id: book.user?.userid,
    }));
  },

  mappIssuedBook(books) {
    return books.map((item) => ({
      ...item,
      book_id: item.book?.book_id,
      title: item.book?.title,
      author: item.book?.author,
      userid: item.user?.userid,
      user_name: item.user?.name,
      issuedByName: item?.issuedBy?.name,
      returnByName: item?.returnBy?.name,
    }));
  },

  mapTableData(records, types) {
    const data = types?.map((type) => {
      let items = records
        .filter((item) => item.typeId === type.id)
        .map((item) => item.book_id);

      return {
        ...type,
        total: items.length,
        data: utils.mapRowData(items, 8),
      };
    });
    return data;
  },

  mapRowData(records, perRowRecord) {
    const recordPerRow = perRowRecord || 5;
    const body = [];
    const itemLength = records?.length;
    let start = 0;
    let end = itemLength < recordPerRow ? itemLength : recordPerRow;
    while (start < end) {
      body.push(records?.slice(start, end));
      start = end;
      end = itemLength < end + recordPerRow ? itemLength : end + recordPerRow;
    }
    return body;
  },

  dateToMMDDYYYY(dateString) {
    if (!dateString) return "";
    var parts = dateString.split("-");

    return (
      parseInt(parts[1], 10) +
      "/" +
      parseInt(parts[2], 10) +
      "/" +
      parseInt(parts[0], 10)
    );
  },

  dateToYYYYMMDD(dateString) {
    let date = new Date(dateString),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  },

  dateFormated(dateString) {
    if (!dateString) return "";

    const createdDate = new Date(dateString);
    const formatedDate = createdDate?.toDateString();
    if (formatedDate === "Invalid Date") return "";
    else return formatedDate.slice(4, formatedDate.length);
  },

  generateUserForm(record, file) {
    const formData = new FormData();
    if (record.id) formData.append("id", record.id);
    formData.append("name", record.name);
    formData.append("userid", record.userid);
    formData.append("roleId", record.roleId);
    formData.append("designation", record.designation);
    formData.append("contact_no", record.contact_no);
    formData.append("email", record.email);
    formData.append("housename", record.housename);
    formData.append("batch_no", record.batch_no);
    formData.append("image", record.image);
    formData.append("file", file);

    return formData;
  },

  validateBook(record) {
    if (!record.book_id) return "Please complete 'Book Id' field!";
    else if (!record.title) return "Please complete 'Title' field!";
    else if (!record.typeId) return "Please complete 'Type' field!";
    else return false;
  },

  validateUser(record) {
    if (!record.userid) return "Please complete 'User Id' field!";
    else if (!record.name) return "Please complete 'Name' field!";
    else if (!record.roleId) return "Please complete 'Role' field!";
    else return false;
  },

  isJsonString(text) {
    if (typeof text !== "string") {
      return false;
    }
    try {
      JSON.parse(text);
      return true;
    } catch (error) {
      return false;
    }
  },
};

export default utils;
