import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import * as actions from "../../constants/actions";
import Dropdown from "./dropdown";
import { BookAPI } from "../../api";
import { useBookProvider } from "../BookContext";
import { processAPIError } from "../../api/parent";

export default function AddBookForm(props) {
  const { handleSubmitRecord, handleCancel } = props;
  const { bookTypes, dispatch } = useBookProvider();
  const [isType, setIsType] = useState(false);
  const [type, setType] = useState("");
  const [typeId, setTypeId] = useState("");

  const handleTypeSelect = (typeId) => setTypeId(typeId);

  const handleAddType = async () => {
    try {
      const response = await BookAPI.addType({ title: type });
      dispatch({ type: actions.ADD_TYPE, payload: response });
      dispatch({
        type: actions.SUCCESS,
        payload: "Book type added successfully",
      });
      setTypeId(response.id);
      setIsType(false);
      setType("");
    } catch (error) {
      dispatch({ type: actions.ERROR, payload: processAPIError(error) });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const record = {
      book_id: data.get("bookid"),
      title: data.get("title"),
      author: data.get("author"),
      publisher: data.get("publisher"),
      publication_year: data.get("publication_year"),
      edition: data.get("edition"),
      price: data.get("price"),
      pages: data.get("pages"),
      shelf_no: data.get("shelf_no"),
      typeId: typeId ? typeId : null,
      note: data.get("note"),
    };

    const response = await handleSubmitRecord(record);

    if (response) {
      event.target.reset();
      setTypeId("");
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit} noValidate={false}>
      <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6}>
          <TextField
            fullWidth
            required
            type="string"
            name="bookid"
            id="bookid"
            size="small"
            label="Book Id"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={6}>
          <Box sx={{ display: "flex" }}>
            <Dropdown
              defaultValue={typeId}
              dropdownItems={bookTypes}
              handleTypeSelect={handleTypeSelect}
              pickListLable="Book Type"
            />
            <IconButton
              color="primary"
              aria-label="add book type"
              onClick={() => setIsType(!isType)}
            >
              <AddCircleOutlineRoundedIcon />
            </IconButton>
          </Box>
          {isType && (
            <Box sx={{ display: "flex", mt: 1 }}>
              <TextField
                fullWidth
                type="string"
                size="small"
                label="Add Role"
                variant="outlined"
              />
              <IconButton color="primary" size="small" onClick={handleAddType}>
                <AddRoundedIcon />
              </IconButton>
            </Box>
          )}
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            required
            type="string"
            name="title"
            id="title"
            size="small"
            label="Book Title"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            type="string"
            name="author"
            id="author"
            size="small"
            label="Author"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            type="string"
            name="price"
            id="price"
            size="small"
            label="Price"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            type="string"
            name="publisher"
            id="publisher"
            size="small"
            label="Publisher"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            type="string"
            name="pages"
            id="pages"
            size="small"
            label="Pages"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            type="text"
            name="publication_year"
            id="publication_year"
            size="small"
            label="Publication Year & Place"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            type="string"
            name="shelf_no"
            id="shelf_no"
            size="small"
            label="Shelf Number"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            type="string"
            name="edition"
            id="edition"
            size="small"
            label="Edition"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            name="note"
            id="note"
            size="small"
            label="Note"
            variant="outlined"
            multiline
            rows={4}
          />
        </Grid>
        <Grid item xs={12} sx={{ mt: 1 }}>
          <Stack spacing={2} direction="row" sx={{ justifyContent: "center" }}>
            <Button
              type="button"
              variant="outlined"
              size="small"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button type="submit" variant="contained" size="small">
              Save
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}
