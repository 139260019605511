import React, { useEffect, useState } from "react";
import { issueFieldsCurrent, issueFieldsPrevious } from "../../models";
import { useBookProvider } from "../BookContext";
import { loggedInUser } from "../../api/actions";
import { processAPIError } from "../../api/parent";
import { DOMAIN } from "../../config";
import IssuedBookList from "./issuedBookList";
import GetUserDialog from "../Common/getUserDialog";
import PreviouslyIssuedList from "./previouslyIssuedList";
import utils from "../utils";
import BookAPI from "../../api/bookAPI";
import * as actions from "../../constants/actions";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import services from "../../services";
import CustomReportButton from "../CustomReportButton";
import Stack from "@mui/material/Stack";
import Loading from "../Loader/Loading";
import Avatar from "@mui/material/Avatar";

export default function ReturnBook() {
  const { books, userid, issuedBooks, users, dispatch, readOnly } =
    useBookProvider();
  const [userIssuedBooks, setUserIssuedBooks] = useState([]);
  const [currentIssuedBooks, setCurrentIssuedBooks] = useState([]);
  const [previousIssuedBooks, setPreviousIssuedBooks] = useState([]);
  const [isOpen, setIsOpen] = React.useState(!userid ? true : false);
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(false);
  const authUser = loggedInUser();

  useEffect(() => {
    if (!authUser?.librarian) handleUserSelect(authUser.userid);
  }, [authUser]);

  const getIssuedBooksByUserId = async (id) => {
    try {
      setLoading(true);
      setUserIssuedBooks(await BookAPI.getIssuedBooksByUserId(id));
    } catch (error) {
      dispatch({ type: actions.ERROR, payload: processAPIError(error) });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const current = userIssuedBooks.filter(
      (item) => !item.return_date && item.active
    );
    const previous = userIssuedBooks.filter(
      (item) => item.return_date && item.active
    );

    const mappCurrent = utils.mappIssuedBook(current);
    const mappPrevious = utils.mappIssuedBook(previous);

    setCurrentIssuedBooks(mappCurrent ? mappCurrent : []);
    setPreviousIssuedBooks(mappPrevious ? mappPrevious : []);
  }, [userIssuedBooks]);

  React.useEffect(() => {
    const userInfo = users.find((item) => item.userid === userid);
    setUser(userInfo);

    if (userid) getIssuedBooksByUserId(userInfo?.id);
    else {
      setCurrentIssuedBooks([]);
      setPreviousIssuedBooks([]);
    }
  }, [userid, users]);

  const handleReturn = async (data) => {
    try {
      dispatch({ type: actions.LOADING, payload: true });
      const mappedData = data.map((item) => ({
        id: item.id,
        bookId: item.bookId,
        return_date: Date.now(),
        returnById: authUser?.id,
      }));

      const response = await BookAPI.returnBook(mappedData);

      setUserIssuedBooks(await BookAPI.getIssuedBooksByUserId(user?.id));

      dispatch({
        type: actions.SUCCESS,
        payload: "Book returned successfully!",
      });

      if (books.length)
        dispatch({ type: actions.RETURN_BOOKS, payload: response });
    } catch (error) {
      dispatch({ type: actions.ERROR, payload: processAPIError(error) });
    } finally {
      dispatch({ type: actions.LOADING, payload: false });
    }
  };

  const updateRecords = (payload) => {
    const issuedIds = payload.map((item) => item.id);
    const updateIssuedBooks = userIssuedBooks.map((item) => {
      if (issuedIds.includes(item.id)) {
        const issuedBook = payload.find((item2) => item2.id === item.id);
        return {
          ...item,
          renew_date: issuedBook.renew_date,
          updatedAt: issuedBook.updatedAt,
        };
      } else return item;
    });
    setUserIssuedBooks(updateIssuedBooks);
    return;
  };

  const handleRenew = async (data) => {
    try {
      dispatch({ type: actions.LOADING, payload: true });
      const mappedData = data.map((item) => ({
        id: item.id,
        bookId: item.bookId,
        renew_date: Date.now(),
      }));

      const response = await BookAPI.renewBook(mappedData);
      updateRecords(response);
      dispatch({ type: actions.RENEW_BOOKS, payload: response });
    } catch (error) {
      dispatch({ type: actions.ERROR, payload: processAPIError(error) });
    } finally {
      dispatch({ type: actions.LOADING, payload: false });
    }
  };

  const handleUserSelect = (id) => {
    dispatch({ type: actions.USER_ID, payload: id });
    setIsOpen(false);
  };

  const handleClick = () => setIsOpen(true);

  const handleReport = () => {
    const mappedCurrent = currentIssuedBooks.map((item) => ({
      ...item,
      type: books.find((book) => book.id === item.bookId)?.type?.title,
    }));

    const mappedPrevious = previousIssuedBooks.map((item) => ({
      ...item,
      type: books.find((book) => book.id === item.bookId)?.type?.title,
    }));

    services.issuedReport(mappedCurrent, mappedPrevious);
  };

  return (
    <>
      {!readOnly && (
        <Paper sx={{ p: 1, mb: 1, boxShadow: "0 1px 1px rgba(0,0,0,.25)" }}>
          <Grid
            container
            spacing={2}
            sx={{ fontSize: "0.875rem" }}
            alignItems="center"
          >
            <Grid
              item
              xs={6}
              sx={{ textAlign: "left", display: "flex", alignItems: "center" }}
            >
              {user && (
                <>
                  <Avatar
                    style={{ display: "inline-block", marginRight: "10px" }}
                    src={`${DOMAIN}/${user?.image}`}
                    variant="square"
                  />
                  <Typography variant="body2" sx={{ pl: 1 }}>
                    User Id: <strong>{user?.userid}</strong> <br />
                    Name: <strong>{user?.name}</strong>
                  </Typography>
                </>
              )}
            </Grid>
            <Grid item xs={6}>
              <Stack spacing={2} direction="row" sx={{ float: "right" }}>
                <CustomReportButton handleClick={handleReport} />
                <Button
                  variant="outlined"
                  size="small"
                  onClick={handleClick}
                  sx={{ textTransform: "capitalize" }}
                  disableElevation
                >
                  Change User
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Paper>
      )}

      {loading && <Loading />}
      {!loading && (
        <>
          <IssuedBookList
            headCells={issueFieldsCurrent}
            records={currentIssuedBooks}
            handleReturn={handleReturn}
            handleRenew={handleRenew}
          />

          <PreviouslyIssuedList
            headCells={issueFieldsPrevious}
            records={previousIssuedBooks}
          />
        </>
      )}

      {isOpen && (
        <GetUserDialog
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          handleUserSelect={handleUserSelect}
        />
      )}
    </>
  );
}
