import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import CustomTable from "../CustomTable";
import { BookAPI } from "../../api";
import { useBookProvider } from "../BookContext";
import { processAPIError } from "../../api/parent";
import * as actions from "../../constants/actions";

export default function PreviouslyIssuedList(props) {
  const { headCells, records } = props;
  const { dispatch } = useBookProvider();

  const [rows, setRows] = React.useState([]);

  React.useEffect(() => {
    setRows(records ? records : []);
  }, [records]);

  const handleRemove = async (id) => {
    try {
      dispatch({ type: actions.LOADING, payload: true });
      await BookAPI.removeIssuedBook(id);
      dispatch({ type: actions.REMOVE_ISSUED_BOOK, payload: id });
    } catch (error) {
      dispatch({ type: actions.ERROR, payload: processAPIError(error) });
    } finally {
      dispatch({ type: actions.LOADING, payload: false });
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <Typography variant="subtitle1" sx={{ pl: 2, pb: 1, pt: 1 }}>
          Previously Issued Books <strong>({rows?.length})</strong>
        </Typography>
        <CustomTable
          headCells={headCells}
          rows={rows}
          handleRemove={handleRemove}
          isDelete={true}
          isEdit={false}
        />
      </Paper>
    </Box>
  );
}
