import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";
import { DOMAIN } from "../../config";

const RecordTable = (props) => {
  const { record, headCells } = props;
  return (
    <Paper
      sx={{ border: "1px solid rgba(224, 224, 224, 1)", boxShadow: "none" }}
    >
      <TableContainer>
        <Table size="small">
          <TableBody>
            {headCells.map((cell, index) => (
              <TableRow key={index}>
                <TableCell variant="head" align="right">
                  {cell.label} :
                </TableCell>
                <TableCell variant="body" align="left">
                  {cell?.type !== "image" && record[cell.id]}
                  {cell?.type === "image" && (
                    <Avatar
                      src={`${DOMAIN}/${record[cell.id]}`}
                      variant="square"
                    />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function ConfirmationModal(props) {
  const { isOpen, data, headCells, handleDecision } = props;

  return (
    <Dialog
      open={isOpen}
      TransitionComponent={Transition}
      keepMounted
      fullWidth
      onClose={handleDecision(false)}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>Do you want to remove this item?</DialogTitle>
      <DialogContent dividers>
        {data && <RecordTable record={data} headCells={headCells} />}
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Button
          onClick={handleDecision(false)}
          variant="outlined"
          size="small"
          sx={{ textTransform: "capitalize" }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleDecision(true)}
          autoFocus
          variant="contained"
          color="error"
          disableElevation
          size="small"
          sx={{ textTransform: "capitalize" }}
        >
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  );
}
