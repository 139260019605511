import GroupIcon from "@mui/icons-material/Group";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import MultipleStopIcon from "@mui/icons-material/MultipleStop";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import SdStorageIcon from "@mui/icons-material/SdStorage";
import RemoveModeratorIcon from "@mui/icons-material/RemoveModerator";
import CardTravelIcon from "@mui/icons-material/CardTravel";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import Tooltip from "@mui/material/Tooltip";
import SettingsIcon from "@mui/icons-material/Settings";
import Paths from "./Paths";
import { iconColor } from "./parameters";

export const commonNavItems = [
  {
    title: "User's Information",
    icon: () => (
      <Tooltip title="User Information" placement="right-start">
        <GroupIcon sx={{ color: iconColor }} />
      </Tooltip>
    ),
    route: Paths.USERS(),
  },
  {
    title: "Book's Information",
    icon: () => (
      <Tooltip title="Book Information" placement="right-start">
        <MenuBookIcon sx={{ color: iconColor }} />
      </Tooltip>
    ),
    route: Paths.BOOKS(),
  },
  {
    title: "Issue Book",
    icon: () => (
      <Tooltip title="Issue Book" placement="right-start">
        <MultipleStopIcon sx={{ color: iconColor }} />
      </Tooltip>
    ),
    route: Paths.ISSUE_BOOK(),
  },
  {
    title: "Return Book",
    icon: () => (
      <Tooltip title="Return Book" placement="right-start">
        <MultipleStopIcon sx={{ color: iconColor }} />
      </Tooltip>
    ),
    route: Paths.RETURN_BOOK(),
  },
  {
    title: "All Issued Books",
    icon: () => (
      <Tooltip title="All Issued Books" placement="right-start">
        <LibraryBooksIcon sx={{ color: iconColor }} />
      </Tooltip>
    ),
    route: Paths.ISSUED_BOOKS(),
  },
  {
    title: "Ebook",
    icon: () => (
      <Tooltip title="Ebook" placement="right-start">
        <CardTravelIcon sx={{ color: iconColor }} />
      </Tooltip>
    ),
    route: Paths.EBOOK(),
  },
  {
    title: "Notice",
    icon: () => (
      <Tooltip title="Notice" placement="right-start">
        <NotificationsActiveIcon sx={{ color: iconColor }} />
      </Tooltip>
    ),
    route: Paths.NOTICE(),
  },
  {
    title: "Written Off Books",
    icon: () => (
      <Tooltip title="Written Off Books" placement="right-start">
        <RemoveModeratorIcon sx={{ color: iconColor }} />
      </Tooltip>
    ),
    route: Paths.WRITTEN_OFF_BOOKS(),
  },
];

export const secretNavItems = [
  {
    title: "Stock Taking",
    icon: () => (
      <Tooltip title="Stock Taking" placement="right-start">
        <SdStorageIcon sx={{ color: iconColor }} />
      </Tooltip>
    ),
    route: Paths.STOCK_TAKING(),
  },
  {
    title: "Setting",
    icon: () => (
      <Tooltip title="Setting" placement="right-start">
        <SettingsIcon sx={{ color: iconColor }} />
      </Tooltip>
    ),
    route: Paths.SETTING(),
  },
];
