import { createTheme } from "@mui/material/styles";

// const fontSize075 = "0.75rem";
const fontSize875 = "0.875rem";

const recordDetailTheme = createTheme({
  palette: {},
  components: {
    MuiGrid: {
      styleOverrides: {
        item: {
          paddingTop: "10px !important",
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          fontSize: fontSize875,
          color: "rgba(0,0,0,1) !important",
          marginTop: "12px !important",
        },
        input: {
          paddingBottom: "1px",
          "&.Mui-disabled": {
            color: "rgba(0,0,0,.87)",
            WebkitTextFillColor: "rgba(0,0,0,.87) ",
          },
        },
      },
    },

    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: fontSize875,
          top: 0,
          color: "rgba(0,0,0,.75) !important",
        },
      },
    },
  },
});

export default recordDetailTheme;
