import React from "react";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import recordDetailTheme from "../Theme/recordDetailTheme";
import Avatar from "@mui/material/Avatar";
import { ThemeProvider } from "@emotion/react";
import { DOMAIN } from "../../config";

export default function UserDetailsForm(props) {
  const { record } = props;

  return (
    <ThemeProvider theme={recordDetailTheme}>
      <Grid
        container
        rowSpacing={2}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        sx={{ p: 1 }}
      >
        <Grid item xs={8} rowSpacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              disabled
              size="small"
              label="User Id"
              variant="standard"
              value={record.userid}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              disabled
              size="small"
              label="Name"
              variant="standard"
              value={record.name}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              disabled
              size="small"
              label="Role"
              variant="standard"
              value={record.role?.title}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              disabled
              size="small"
              label="Designation"
              variant="standard"
              value={record.designation}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              disabled
              size="small"
              label="Contact Info"
              variant="standard"
              value={record.contact_no}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              disabled
              size="small"
              label="Email"
              variant="standard"
              value={record.email}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              disabled
              size="small"
              label="Batch"
              variant="standard"
              value={record.batch_no}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              disabled
              size="small"
              label="Housename"
              variant="standard"
              value={record.housename}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={4} sx={{ textAlign: "center", alignItems: "center" }}>
          {record.image && (
            <img
              src={`${DOMAIN}/${record.image}`}
              alt={record.name}
              loading="lazy"
              width={90}
            />
          )}
          {!record.image && (
            <Avatar variant="square" sx={{ display: "inline-flex" }} />
          )}
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
