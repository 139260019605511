import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useBookProvider } from "../../BookContext";
import Loading from "../../Loader/Loading";
import services from "../../../services";
import ReportTable from "../../ReportTable";
import utils from "../../utils";

export default function ReportWrittenOffBooks() {
  const { books, bookTypes, readOnly } = useBookProvider();
  const [records, setRecords] = useState([]);
  const [numOfBooks, setNumOfBooks] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const writtenOff = books.filter((item) => item.written_off);
    setNumOfBooks(writtenOff?.length);
    setRecords(utils.mapTableData(writtenOff, bookTypes));
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, [books, bookTypes]);

  const handleReport = () => services.writtenOffTaking(records);

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography variant="subtitle1">
            Total Written Off Books: {numOfBooks}
          </Typography>
        </Grid>
        <Grid item xs={6} align="right">
          {!readOnly && (
            <Button
              variant="contained"
              size="small"
              disableElevation
              onClick={handleReport}
            >
              Download Report
            </Button>
          )}
        </Grid>
      </Grid>
      <Box>
        {loading && <Loading />}
        {!loading && <ReportTable records={records} />}
      </Box>
    </Box>
  );
}
