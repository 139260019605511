import React from "react";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Zoom from "@mui/material/Zoom";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";

export default function CustomReportButton(props) {
  const { handleClick, title } = props;

  return (
    <Tooltip title="Download Report" placement="top" TransitionComponent={Zoom}>
      <IconButton
        size="small"
        color="success"
        aria-label="remove"
        onClick={handleClick}
      >
        <CloudDownloadIcon />
      </IconButton>
    </Tooltip>
  );
}
