import jsPDF from "jspdf";
import "jspdf-autotable";

const dataListGenerator = (records, title, columnStyles) => {
  var doc = new jsPDF({ orientation: "landscape" });
  var finalY = doc.lastAutoTable.finalY || 10;

  doc.text("Library Management System(RCC)", 14, 14);
  doc.setFontSize(11);
  records.forEach((record) => {
    doc.text(record.title + "(" + record.body?.length + ")", 14, finalY + 10);
    doc.autoTable({
      columns: record.columns,
      body: record.body,
      startY: finalY + 15,
      theme: "grid",
      columnStyles: columnStyles,
    });
    finalY = doc.lastAutoTable.finalY;
  });

  doc.save(`${title}_report.pdf`);
};

export default dataListGenerator;
