import axios from "axios";
import utils from "../components/utils";
import { BASE_URL } from "../config";
import { getCookie } from "./actions";

const apiInstance = axios.create({
  baseURL: BASE_URL,
});

apiInstance.interceptors.request.use((config) => {
  const token = getCookie("token");
  if (token) config.headers.Authorization = `Bearer ${token}`;
  return config;
});

export { apiInstance };

export const processApiResponse = (response) => {
  return new Promise((resolve, reject) => {
    if (response.status >= 200 && response.status < 400) {
      utils.isJsonString(response.data)
        ? resolve(JSON.parse(response.data))
        : resolve(response.data);
    } else if (response.status === 401) {
      reject({ message: "You are unauthenticated, please log in again" });
    } else {
      reject({
        message: response.data?.message || response?.data?.statusMessage,
      });
    }
  });
};

export const processAPIError = (error) => {
  if (error.response?.status === 401) {
    return "You are unauthenticated, please log in again!";
  } else if (error.response?.status === 404) {
    return "Bad Api Request!";
  } else if (error.response?.status >= 400 && error.response?.status <= 500) {
    return error.response?.data?.message;
  } else if (error.response) {
    return error.response;
  } else if (error.request) {
    return "Bad request!";
  } else {
    return error.message;
  }
};
