import { createTheme } from "@mui/material/styles";

const fontSize075 = "0.75rem";
const fontSize875 = "0.875rem";

const globalTheme = createTheme({
  palette: {},
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: fontSize075,
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          fontSize: fontSize875,
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          fontSize: fontSize875,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontSize: fontSize875,
        },
        input: {
          fontSize: fontSize875,
          padding: "6.5px 14px",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: fontSize875,
          top: -2,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: fontSize875,
          textTransform: "capitalize",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          textTransform: "capitalize",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          // borderRight: "1px solid rgba(224, 224, 224, 0.5)",
          textTransform: "capitalize",
        },
        head: {
          fontWeight: "600",
        },
        body: {
          cursor: "pointer",
        },
      },
    },
  },
});

export default globalTheme;
