import React, { useEffect } from "react";
import toast, { Toaster, ToastBar } from "react-hot-toast";

export default function Alert({ alert }) {
  useEffect(() => {
    if (alert.success) toast.success(alert.message);
    if (alert.error) toast.error(alert.message);
  }, [alert]);

  return (
    <Toaster>
      {(t) => (
        <ToastBar toast={t}>
          {({ icon, message }) => (
            <>
              {icon}
              {message}
              {t.type !== "loading" && (
                <button onClick={() => toast.dismiss(t.id)}>X</button>
              )}
            </>
          )}
        </ToastBar>
      )}
    </Toaster>
  );
}
