import * as React from "react";
import { useParams, Link } from "react-router-dom";
import { UserAPI } from "../../api";
import { styled } from "@mui/material/styles";
import Paths from "../../constants/Paths";
import MuiCard from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import Alert from "@mui/material/Alert";
import { useNavigate } from "react-router-dom";

const Card = styled(MuiCard)(({ theme }) => ({
  backgroundColor: "#118ed6",
  textAlign: "center",
  color: "#ffffff",
  marginBottom: 2,
  cursor: "pointer",
  "& a": {
    color: "#ffffff",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function IssueBookByUser() {
  const { id } = useParams();
  const [error, setError] = React.useState(null);
  const navigate = useNavigate();

  React.useEffect(() => {
    (async () => {
      const response = await UserAPI.getUser(id);
      if (!response)
        setError("User does not exist. Please add the user first.");
    })();
  }, [id]);

  const handleIssue = () => navigate("/library/issue-book/" + id);
  const handleReturn = () => navigate("/library/return-book/" + id);

  return (
    <div>
      <Dialog
        open={true}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        size="md"
        fullWidth
      >
        <DialogTitle>
          {error ? "Error" : "Please choose one options from bellow"}
        </DialogTitle>
        <DialogContent>
          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}
          {!error && (
            <>
              <Card onClick={handleIssue}>
                <CardContent sx={{ p: 3 }}>
                  <Link to={Paths.ISSUE_BOOK_BY_ID(id)}>Issue Book</Link>
                </CardContent>
              </Card>

              <Card onClick={handleReturn}>
                <CardContent sx={{ p: 3 }}>
                  <Link to={Paths.RETURN_BOOK_BY_ID(id)}>Return Book</Link>
                </CardContent>
              </Card>
            </>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}
