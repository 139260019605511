import React from "react";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Dropdown from "./dropdown";
import { useBookProvider } from "../BookContext";

export default function BookForm(props) {
  const { record, setRecord } = props;
  const { bookTypes } = useBookProvider();
  const handleTypeSelect = (typeId) => {
    setRecord({ ...record, typeId: typeId });
  };

  const handleChange = (field) => (event) => {
    setRecord({ ...record, [field]: event.target.value });
  };

  return (
    <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={6}>
        <TextField
          fullWidth
          required
          type="string"
          size="small"
          label="Book Id"
          variant="outlined"
          value={record.book_id ? record.book_id : ""}
          onChange={handleChange("book_id")}
          error={!record.book_id ? true : false}
        />
      </Grid>

      <Grid item xs={6}>
        <Dropdown
          defaultValue={record.typeId}
          dropdownItems={bookTypes}
          handleTypeSelect={handleTypeSelect}
          pickListLable="Book Type"
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          required
          type="string"
          size="small"
          label="Book Title"
          variant="outlined"
          value={record.title ? record.title : ""}
          onChange={handleChange("title")}
          error={!record.title ? true : false}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          type="string"
          size="small"
          label="Author"
          variant="outlined"
          value={record.author ? record.author : ""}
          onChange={handleChange("author")}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          type="string"
          size="small"
          label="Price"
          variant="outlined"
          value={record.price ? record.price : ""}
          onChange={handleChange("price")}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          type="string"
          size="small"
          label="Publisher"
          variant="outlined"
          value={record.publisher ? record.publisher : ""}
          onChange={handleChange("publisher")}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          type="string"
          size="small"
          label="Pages"
          variant="outlined"
          value={record.pages ? record.pages : ""}
          onChange={handleChange("pages")}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          type="text"
          size="small"
          label="Publication Year & Place"
          variant="outlined"
          value={record.publication_year ? record.publication_year : ""}
          onChange={handleChange("publication_year")}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          type="string"
          size="small"
          label="Shelf Number"
          variant="outlined"
          value={record.shelf_no ? record.shelf_no : ""}
          onChange={handleChange("shelf_no")}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          type="string"
          size="small"
          label="Edition"
          variant="outlined"
          value={record.edition ? record.edition : ""}
          onChange={handleChange("edition")}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          size="small"
          label="Note"
          variant="outlined"
          multiline
          rows={4}
          value={record.note ? record.note : ""}
          onChange={handleChange("note")}
        />
      </Grid>
    </Grid>
  );
}
