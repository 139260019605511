import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import { AuthAPI } from "../../api";

const initUser = {
  name: "",
  userid: "",
  password: "",
  cPassword: "",
};

const initStatus = { error: false, success: false, message: "" };

export default function AddLibrarian() {
  const [response, setResponse] = React.useState(initStatus);
  const [user, setUser] = useState(initUser);

  const handleChange = (key) => (event) => {
    setUser({ ...user, [key]: event.target.value });
  };

  const handleSubmit = async () => {
    const signUpData = {
      name: user.name,
      userid: user.userid,
      password: user.password,
    };
    const isError = !user.name || !user.userid || !user.password;
    if (isError) {
      setResponse({
        success: false,
        error: true,
        message: "Please fill up all required fields!",
      });
      return;
    }

    try {
      await AuthAPI.addLibrarian(signUpData);
      setResponse({
        success: true,
        error: false,
        message: "Successfull saved the librarian",
      });
    } catch (error) {
      setResponse({ success: false, error: true, message: error.message });
    }
  };

  return (
    <Card sx={{ maxWidth: 400, margin: "0 auto" }}>
      <CardContent>
        <Grid sx={{ textAlign: "center" }}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Add Librarian
          </Typography>
        </Grid>
        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12}>
            {(response.success || response.error) && (
              <Alert severity={response.success ? "success" : "error"}>
                {response.message}
              </Alert>
            )}
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              type="text"
              size="small"
              label="Name"
              variant="outlined"
              value={user.name}
              onChange={handleChange("name")}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              type="text"
              size="small"
              label="User Id"
              variant="outlined"
              value={user.userid}
              onChange={handleChange("userid")}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              type="password"
              size="small"
              label="Password"
              variant="outlined"
              value={user.password}
              onChange={handleChange("password")}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              error={user.password !== user.cPassword}
              type="password"
              size="small"
              label="Confirm Password"
              variant="outlined"
              value={user.cPassword}
              onChange={handleChange("cPassword")}
              helperText={
                user.password !== user.cPassword
                  ? "Password did not matched"
                  : ""
              }
            />
          </Grid>
        </Grid>
      </CardContent>
      <CardActions sx={{ justifyContent: "center" }}>
        <Button variant="contained" size="small" onClick={handleSubmit}>
          Save
        </Button>
      </CardActions>
    </Card>
  );
}
