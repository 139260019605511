import React from "react";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import Stack from "@mui/material/Stack";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

export default function ActionIcons(props) {
  const { row, handleRemove, handleEdit, handleAddModal } = props;
  return (
    <Stack direction="row" spacing={1}>
      <Tooltip
        title={"Remove " + (row.name || row.title)}
        placement="top"
        TransitionComponent={Zoom}
      >
        <IconButton
          size="small"
          color="error"
          aria-label="remove"
          onClick={handleRemove(row.id)}
        >
          <DeleteIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={"Edit " + (row.name || row.title)}
        placement="top"
        TransitionComponent={Zoom}
      >
        <IconButton
          size="small"
          color="primary"
          aria-label="edit"
          onClick={handleEdit(row.id)}
        >
          <ModeEditIcon fontSize="small" />
        </IconButton>
      </Tooltip>

      <Tooltip
        title="Make a copy of this book"
        placement="top"
        TransitionComponent={Zoom}
      >
        <IconButton
          size="small"
          color="primary"
          aria-label="edit"
          onClick={handleAddModal(row.id)}
        >
          <ContentCopyIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    </Stack>
  );
}
