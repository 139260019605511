import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useBookProvider } from "../BookContext";

export default function GetUserDialog(props) {
  const { isOpen, setIsOpen, handleUserSelect } = props;
  const [value, setValue] = React.useState("");
  const [error, setError] = React.useState(false);
  const { users } = useBookProvider();

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleSubmit = () => {
    const user = users.find((item) => item.userid === value);
    if (user) handleUserSelect(user.userid);
    else setError(true);
  };

  const handleChange = (event) => {
    setError(false);
    setValue(event.target.value);
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>User Information</DialogTitle>
      <DialogContent>
        <TextField
          error={error}
          autoFocus
          fullWidth
          margin="dense"
          size="small"
          id="userid"
          label="Enter user id or name"
          variant="outlined"
          helperText={error ? "Invalid user id" : ""}
          value={value}
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleSubmit}>Select</Button>
      </DialogActions>
    </Dialog>
  );
}
