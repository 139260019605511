import React, { useEffect, useState } from "react";
import { bookFields } from "../../../models";
import { useBookProvider } from "../../BookContext";
import { BookAPI } from "../../../api";
import { Dropdown } from "../../Form";
import { processAPIError } from "../../../api/parent";
import * as actions from "../../../constants/actions";
import utils from "../../utils";
import services from "../../../services";
import CustomReportButton from "../../CustomReportButton";
import BookTable from "../../Tables/BookTable";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";

export default function AllBook(props) {
  const { bookTypeId } = props;
  const { books, bookTypes, dispatch, readOnly } = useBookProvider();
  const [records, setRecords] = useState([]);
  const [rows, setRows] = useState([]);
  const [selectedType, setSelectedType] = useState(null);

  useEffect(() => {
    handleTypeSelect(bookTypeId);
  }, [bookTypeId]);

  useEffect(() => {
    setRecords(books.filter((item) => !item.written_off));
  }, [books]);

  useEffect(() => {
    setRows(utils.mapBooks(records));
  }, [records]);

  useEffect(() => {
    if (selectedType) {
      setRows(
        utils.mapBooks(
          records.filter((item) => item.typeId === selectedType?.id)
        )
      );
    } else {
      setRows(utils.mapBooks(records));
    }
  }, [selectedType, records]);

  const handleSearch = (event) => {
    const key = event.target.value;
    const filterdBooks = records.filter((item) => {
      return (
        item.title?.toLowerCase()?.indexOf(key) > -1 ||
        item.author?.toLowerCase()?.indexOf(key) > -1 ||
        item.book_id.indexOf(key) > -1
      );
    });
    setRows(utils.mapBooks(filterdBooks));
  };

  const handleTypeSelect = (typeId) => {
    const selected = bookTypes.find((item) => item.id === typeId);
    setSelectedType(selected ? selected : null);
  };

  const handleBookRemove = async (id) => {
    try {
      dispatch({ type: actions.LOADING, payload: true });
      await BookAPI.removeBook(id);
      dispatch({ type: actions.REMOVE_BOOK, payload: id });
    } catch (error) {
      dispatch({ type: actions.ERROR, payload: processAPIError(error) });
    } finally {
      dispatch({ type: actions.LOADING, payload: false });
    }
  };

  const handleSubmit = async (record, status) => {
    try {
      dispatch({ type: actions.LOADING, payload: true });
      if (status === "add") {
        const response = await BookAPI.addBook(record);
        dispatch({ type: actions.ADD_BOOK, payload: response });
      } else {
        await BookAPI.updateBook(record);
        dispatch({ type: actions.UPDATE_BOOK, payload: record });
      }

      dispatch({
        type: actions.SUCCESS,
        payload:
          status === "add"
            ? "Book Added Successfully"
            : "Book Updated Successfully",
      });
    } catch (error) {
      dispatch({ type: actions.ERROR, payload: processAPIError(error) });
    } finally {
      dispatch({ type: actions.LOADING, payload: false });
    }
  };

  const handleReport = () => {
    if (selectedType) services.allbooks(rows, [selectedType]);
    else services.allbooks(records, bookTypes);
  };

  return (
    <Box sx={{ maxWidth: "calc(100vw)" }}>
      <Paper sx={{ p: 1, mb: 2, boxShadow: "0 1px 1px rgba(0,0,0,.25)" }}>
        <Stack direction="row" spacing={1} alignItems="center">
          <TextField
            sx={{ minWidth: "250px", mr: 2 }}
            size="small"
            label="Enter book id, name or author name"
            onChange={handleSearch}
          />

          <Box sx={{ minWidth: "250px" }}>
            <Dropdown
              defaultValue={bookTypeId}
              dropdownItems={bookTypes}
              handleTypeSelect={handleTypeSelect}
              pickListLable="Book Type"
            />
          </Box>

          <Chip
            label={rows?.length}
            color="success"
            size="small"
            variant="contained"
          />
          {!readOnly && <CustomReportButton handleClick={handleReport} />}
        </Stack>
      </Paper>

      <BookTable
        headCells={bookFields}
        rows={rows}
        handleRemove={handleBookRemove}
        handleSubmit={handleSubmit}
      />
    </Box>
  );
}
