import React, { useEffect, useState } from "react";
import CustomTab from "../CustomTab";
import AllIssuedBooks from "./AllIssuedBooks";
import { useBookProvider } from "../BookContext";
import Loading from "../Loader/Loading";

export default function IssuedBooks() {
  const { getIssuedBooks, issuedBooks } = useBookProvider();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      if (!issuedBooks.length) {
        setLoading(true);
        await getIssuedBooks();
        setLoading(false);
      }
    })();
  }, []);

  const tabItems = [
    {
      title: "Current Issued Books",
      index: 0,
      component: () => <AllIssuedBooks current={true} />,
    },
    {
      title: "Previous Records",
      index: 1,
      component: () => <AllIssuedBooks current={false} />,
    },
  ];

  return <>{loading ? <Loading /> : <CustomTab tabs={tabItems} />}</>;
}
