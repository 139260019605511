import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import CustomTable0 from "../../Tables/CustomTable0";
import RollbackDialog from "./RollbackDialog";
import { writtenOffBookFields } from "../../../models";
import { useBookProvider } from "../../BookContext";
import { Dropdown } from "../../Form";
import { BookAPI } from "../../../api";
import { processAPIError } from "../../../api/parent";
import * as actions from "../../../constants/actions";

const EnhancedTableToolbar = (props) => {
  const {
    handleDisplay,
    handleSearch,
    handleClear,
    handleTypeSelect,
    bookTypes,
    numSelected,
  } = props;

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <TextField
        sx={{ minWidth: "250px", mr: 2 }}
        size="small"
        label="Enter book id or name"
        onChange={(event) => handleSearch(event.target.value)}
      />

      <Box sx={{ minWidth: "250px" }}>
        <Dropdown
          defaultValue=""
          dropdownItems={bookTypes}
          handleTypeSelect={handleTypeSelect}
          pickListLable="Book Type"
        />
      </Box>
      {numSelected > 0 && (
        <>
          <Chip
            label={numSelected}
            color="success"
            size="small"
            variant="contained"
          />
          <Chip
            label="Clear"
            color="error"
            size="small"
            variant="contained"
            onClick={handleClear}
          />
          <Chip
            label="Rollback"
            color="success"
            size="small"
            variant="outlined"
            onClick={() => handleDisplay(false)}
          />
          <Chip
            label="Delete"
            color="error"
            size="small"
            variant="outlined"
            onClick={() => handleDisplay(true)}
          />
        </>
      )}
    </Stack>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  handleSearch: PropTypes.func.isRequired,
  handleDisplay: PropTypes.func.isRequired,
};

export default function AllWrittenOffBook(props) {
  const { books, bookTypes, dispatch } = useBookProvider();
  const [records, setRecords] = React.useState([]);
  const [rows, setRows] = React.useState([]);
  const [selected, setSelected] = React.useState([]);
  const [showItems, setShowItems] = React.useState(false);
  const [isRemove, setIsRemove] = React.useState(false);

  React.useEffect(() => {
    setRecords(
      books
        .filter((item) => item.written_off)
        .map((item) => ({ ...item, type_name: item.type?.title }))
    );
  }, [books]);

  React.useEffect(() => {
    setRows(records.map((item) => ({ ...item, type_name: item.type?.title })));
  }, [records]);

  const handleDisplaySelectedItems = (remove) => {
    setIsRemove(remove);
    setShowItems(true);
  };

  const handleClear = () => setSelected([]);

  const handleSearch = (key) => {
    const filterdRecords = records.filter(
      (item) =>
        item.title.toLowerCase().indexOf(key) > -1 ||
        item.book_id.indexOf(key) > -1
    );
    setRows(filterdRecords);
  };

  const handleRollback = async () => {
    try {
      setShowItems(false);
      dispatch({ type: actions.LOADING, payload: true });
      const mappedData = selected.map((item) => ({
        ...item,
        written_off: false,
        issuedTo: null,
      }));
      await BookAPI.rollBackBook(mappedData);
      dispatch({ type: actions.ROLLBACK_BOOKS, payload: mappedData });
    } catch (error) {
      dispatch({ type: actions.ERROR, payload: processAPIError(error) });
    } finally {
      dispatch({ type: actions.LOADING, payload: false });
      setSelected([]);
    }
  };

  const handleTypeSelect = (typeId) => {
    if (!typeId) setRows(records);
    else setRows(records.filter((item) => item.typeId === typeId));
  };

  const handleRemove = async () => {
    setShowItems(false);
    setIsRemove(false);
    try {
      dispatch({ type: actions.LOADING, payload: true });
      const mappedData = selected.map((item) => item.id);
      await BookAPI.deleteBook(mappedData);
      dispatch({ type: actions.DELETE_BOOK, payload: mappedData });
    } catch (error) {
      dispatch({ type: actions.ERROR, payload: processAPIError(error) });
    } finally {
      dispatch({ type: actions.LOADING, payload: false });
      setSelected([]);
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ p: 1, mb: 2, boxShadow: "0 1px 1px rgba(0,0,0,.25)" }}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          handleDisplay={handleDisplaySelectedItems}
          handleClear={handleClear}
          handleSearch={handleSearch}
          handleTypeSelect={handleTypeSelect}
          bookTypes={bookTypes}
        />
      </Paper>

      <CustomTable0
        headCells={writtenOffBookFields}
        rows={rows}
        selected={selected}
        setSelected={setSelected}
      />
      {showItems && (
        <RollbackDialog
          isOpen={showItems}
          isRemove={isRemove}
          setIsOpen={setShowItems}
          selected={selected}
          setSelected={setSelected}
          handleRollback={handleRollback}
          handleRemove={handleRemove}
        />
      )}
    </Box>
  );
}
