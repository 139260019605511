import { removeCookie, removeLocalStorage } from "./actions";
import { apiInstance, processApiResponse } from "./parent";

const AuthAPI = {
  async signup(data) {
    const path = "/auth/signup";
    const response = await apiInstance.post(path, data);
    return processApiResponse(response);
  },

  async addLibrarian(data) {
    const path = "/auth/librarian/add";
    const response = await apiInstance.post(path, data);
    return processApiResponse(response);
  },

  async signin(data) {
    const path = "/auth/signin";
    const response = await apiInstance.post(path, data);
    return processApiResponse(response);
  },

  async signout(next) {
    removeCookie("token");
    removeLocalStorage("user");

    const path = "auth/signout";
    const response = await apiInstance.get(path);
    return processApiResponse(response);
  },
};

export default AuthAPI;
