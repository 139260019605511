import React, { useEffect, useState } from "react";
import AsyncSelect from "react-select/async";

const selectStyles = {
  control: (provided) => ({
    ...provided,
  }),
  input: (provided) => ({
    ...provided,
    margin: "1px",
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: "0.875rem",
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: "11",
  }),
  option: (provided, state) => ({
    ...provided,
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
    padding: "0.5rem",
    fontSize: "0.75rem",
    color: "#000000 !important",
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";
    const fontSize = "0.875rem";
    return { ...provided, opacity, transition, fontSize };
  },
};

export default function DropdownAsyncSearch(props) {
  const { options, defaultValue, handleSelectOption } = props;
  const [initOptions, setInitOptions] = useState([]);

  useEffect(() => {
    const initialOptions = options.slice(
      0,
      options.length > 50 ? 50 : options.length
    );
    setInitOptions(initialOptions);
  }, [options]);

  const loadOptions = (inputValue, callback) => {
    setTimeout(() => {
      const filterdRecords = options.filter(
        (item) =>
          item?.label.toLowerCase().includes(inputValue.toLowerCase()) ||
          item?.book_id?.includes(inputValue)
      );
      callback(filterdRecords);
    }, 1000);
  };

  const handleOptionSelect = (event) => handleSelectOption(event.value);

  return (
    <div>
      <AsyncSelect
        cacheOptions
        getOptionLabel={(e) => e.book_id + " - " + e.label}
        loadOptions={loadOptions}
        defaultOptions={initOptions}
        defaultInputValue={defaultValue}
        onChange={handleOptionSelect}
        styles={selectStyles}
        placeholder="Enter book name or id "
      />
    </div>
  );
}
