import * as React from "react";
import CustomTab from "../CustomTab";
import About from "./About";
import ChangePassword from "./ChangePassword";

export default function Profile() {
  const tabItems = [
    {
      title: "About",
      index: 0,
      component: () => <About />,
    },
    {
      title: "Change Password",
      index: 1,
      component: () => <ChangePassword />,
    },
  ];

  return <CustomTab defaultTab={0} tabs={tabItems} />;
}
