import * as React from "react";
import * as actions from "../../constants/actions";
import Paths from "../../constants/Paths";
import { useParams } from "react-router-dom";
import { useBookProvider } from "../BookContext";
import { Navigate } from "react-router-dom";

export default function ReturnBookById() {
  const { id } = useParams();
  const { dispatch } = useBookProvider();
  const [isUser, setIsUser] = React.useState(false);

  React.useEffect(() => {
    if (id) {
      dispatch({ type: actions.USER_ID, payload: id });
      setIsUser(true);
    }
  }, [id, dispatch]);

  return isUser ? (
    <Navigate to={Paths.LANDING_PAGE()} />
  ) : (
    <Navigate to={Paths.RETURN_BOOK()} />
  );
}
