import * as React from "react";
import Box from "@mui/material/Box";
import CustomSlider from "./CustomSlider";
import LandingPageContent from "./LandingPageContent";
import { Outlet, useNavigate, useSearchParams } from "react-router-dom";
import { useAuth } from "../../api/actions";

export default function LandingPage() {
  const auth = useAuth();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  React.useEffect(() => {
    const userid = searchParams.get("_userid");
    if (userid) navigate("/issue-return/" + userid);
  }, [searchParams]);

  const handleLogin = () => {
    if (auth) handleRedirect();
    else navigate("/signin");
  };

  const handleRedirect = () => navigate("/library");

  return (
    <Box sx={{ width: "100%" }}>
      <CustomSlider />
      <LandingPageContent handleLogin={handleLogin} />
      <Outlet />
    </Box>
  );
}
