import React from "react";
import "./style.css";
import { APP_TITLE, LOGO } from "../../../config";

export default function LandingPageContent(props) {
  const { handleLogin } = props;
  return (
    <div className="content">
      <div className="contentBody">
        <div className="logo">
          <img src={LOGO} alt="Application logo" />
        </div>
        <div className="title">
          <h1 className="head1">Library Management System</h1>
          <h3>{APP_TITLE}</h3>
        </div>
        <div className="contentButton">
          <button onClick={handleLogin} style={{ textTransform: "capitalize" }}>
            Log In
          </button>
        </div>
      </div>
      {/* <div className="contentFooter">Footer</div> */}
    </div>
  );
}
