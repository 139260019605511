import * as React from "react";
import CustomTab from "../CustomTab";
import AddLibrarian from "./AddLibrarian";
import LibrarianList from "./LibrarianList";

export default function Setting() {
  const tabItems = [
    {
      title: "List Of Librarian",
      index: 0,
      component: () => <LibrarianList />,
    },
    {
      title: "Add Librarian",
      index: 1,
      component: () => <AddLibrarian />,
    },
  ];

  return <CustomTab defaultTab={0} tabs={tabItems} />;
}
