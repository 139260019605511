import React from "react";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { ThemeProvider } from "@emotion/react";
import recordDetailTheme from "../Theme/recordDetailTheme";
import utils from "../utils";

export default function RecordDetails(props) {
  const { headCells, record } = props;

  return (
    <ThemeProvider theme={recordDetailTheme}>
      <Grid
        container
        rowSpacing={2}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        sx={{ p: 1 }}
      >
        {headCells.map((cell, index) => (
          <Grid item xs={6} key={index}>
            <TextField
              fullWidth
              disabled
              size="small"
              label={cell.label}
              variant="standard"
              value={
                cell.type === "date"
                  ? utils.dateFormated(record[cell.id])
                  : record[cell.id]
                  ? record[cell.id]
                  : ""
              }
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        ))}
      </Grid>
    </ThemeProvider>
  );
}
