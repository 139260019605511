import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";
import { useBookProvider } from "../BookContext";
import { UserAPI } from "../../api";
import { loggedInUser } from "../../api/actions";
import { processAPIError } from "../../api/parent";
import { useNavigate } from "react-router-dom";
import { signOut } from "../../api/actions";
import * as actions from "../../constants/actions";

const initPassword = {
  oldPassword: "",
  newPassword: "",
  cPassword: "",
};

export default function ChangePassword() {
  const { dispatch } = useBookProvider();
  const [password, setPassword] = useState(initPassword);
  const navigate = useNavigate();

  const handleSubmit = async () => {
    const loggedIn = loggedInUser();
    try {
      const data = {
        userid: loggedIn.userid,
        password: password.oldPassword,
        newPassword: password.newPassword,
      };
      await UserAPI.updatePassword(data);
      setPassword(initPassword);
      signOut();
      navigate("/");
    } catch (error) {
      dispatch({ type: actions.ERROR, payload: processAPIError(error) });
    }
  };

  const handleChange = (key) => (event) => {
    setPassword({ ...password, [key]: event.target.value });
  };

  return (
    <Card sx={{ maxWidth: 500, margin: "0 auto" }}>
      <CardContent>
        <Grid sx={{ textAlign: "center" }}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Change Password
          </Typography>
        </Grid>
        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              type="password"
              size="small"
              label="Old Password"
              variant="outlined"
              value={password.oldPassword}
              onChange={handleChange("oldPassword")}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              type="password"
              size="small"
              label="New Password"
              variant="outlined"
              value={password.newPassword}
              onChange={handleChange("newPassword")}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              error={password.newPassword !== password.cPassword}
              type="password"
              size="small"
              label="Confirm Password"
              variant="outlined"
              value={password.cPassword}
              onChange={handleChange("cPassword")}
              helperText={
                password.newPassword !== password.cPassword
                  ? "Password did not matched"
                  : ""
              }
            />
          </Grid>
        </Grid>
      </CardContent>
      <CardActions sx={{ justifyContent: "center" }}>
        <Button variant="contained" size="small" onClick={handleSubmit}>
          Save
        </Button>
      </CardActions>
    </Card>
  );
}
