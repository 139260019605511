const allFields = [
  {
    id: "image",
    numeric: false,
    disablePadding: false,
    label: "Image",
    width: "auto",
    type: "image",
  },
  {
    id: "userid",
    numeric: false,
    disablePadding: false,
    label: "User Id",
    width: "auto",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
    width: "150px",
  },
  {
    id: "designation",
    numeric: false,
    disablePadding: false,
    label: "Designation",
    width: "auto",
  },
  {
    id: "role_name",
    numeric: false,
    disablePadding: false,
    label: "Role",
    width: "auto",
  },
  {
    id: "contact_no",
    numeric: true,
    disablePadding: false,
    label: "Contact Number",
    width: "auto",
  },
  {
    id: "email",
    numeric: false,
    disablePadding: false,
    label: "Email",
    width: "auto",
  },
  {
    id: "housename",
    numeric: false,
    disablePadding: true,
    label: "House Name",
    width: "auto",
  },
  {
    id: "batch_no",
    numeric: true,
    disablePadding: false,
    label: "Batch Number",
    width: "auto",
  },
];

const teacherFields = [
  {
    id: "image",
    numeric: false,
    disablePadding: false,
    label: "Image",
    width: "auto",
    type: "image",
  },
  {
    id: "userid",
    numeric: false,
    disablePadding: false,
    label: "User Id",
    width: "auto",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
    width: "150px",
  },
  {
    id: "designation",
    numeric: false,
    disablePadding: false,
    label: "Designation",
    width: "auto",
  },
  {
    id: "role_name",
    numeric: false,
    disablePadding: false,
    label: "Role",
    width: "auto",
  },
  {
    id: "contact_no",
    numeric: true,
    disablePadding: false,
    label: "Contact Number",
    width: "auto",
  },
  {
    id: "email",
    numeric: false,
    disablePadding: false,
    label: "Email",
    width: "auto",
  },
];

const studentFields = [
  {
    id: "image",
    numeric: false,
    disablePadding: false,
    label: "Image",
    width: "auto",
    type: "image",
  },
  {
    id: "userid",
    numeric: false,
    disablePadding: false,
    label: "User Id",
    width: "auto",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
    width: "150px",
  },
  {
    id: "role_name",
    numeric: false,
    disablePadding: false,
    label: "Role",
    width: "auto",
  },
  {
    id: "contact_no",
    numeric: true,
    disablePadding: false,
    label: "Contact Number",
    width: "auto",
  },
  {
    id: "email",
    numeric: false,
    disablePadding: false,
    label: "Email",
    width: "auto",
  },
  {
    id: "batch_no",
    numeric: true,
    disablePadding: false,
    label: "Batch Number",
    width: "auto",
  },
  {
    id: "housename",
    numeric: false,
    disablePadding: true,
    label: "House Name",
    width: "auto",
  },
];

const userFields = {
  all: allFields,
  teacher: teacherFields,
  student: studentFields,
};

export default userFields;
