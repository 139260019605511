const issueFieldsPrevious = [
  {
    id: "book_id",
    numeric: true,
    disablePadding: false,
    label: "Book Id",
    width: "auto",
  },
  {
    id: "title",
    numeric: false,
    disablePadding: false,
    label: "Title",
    width: "200px",
  },
  {
    id: "author",
    numeric: false,
    disablePadding: false,
    label: "Author",
    width: "200px",
  },
  {
    id: "issue_date",
    numeric: true,
    disablePadding: false,
    label: "Issue Date",
    width: "auto",
    type: "date",
  },
  {
    id: "renew_date",
    numeric: true,
    disablePadding: false,
    label: "Renew Date",
    width: "80px",
    type: "date",
  },
  {
    id: "return_date",
    numeric: true,
    disablePadding: false,
    label: "Return Date",
    width: "80px",
    type: "date",
  },
  {
    id: "returnByName",
    numeric: false,
    disablePadding: false,
    label: "Return By",
    width: "80px",
  },
];

export default issueFieldsPrevious;
