import React, { useEffect, useState } from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

export default function Dropdown(props) {
  const { defaultValue, dropdownItems, handleTypeSelect, pickListLable } =
    props;

  const [typeId, setTypeId] = useState("");

  useEffect(() => {
    setTypeId(defaultValue ? defaultValue : "");
  }, [defaultValue]);

  const handleTypeChange = (event) => {
    setTypeId(event.target.value);
    handleTypeSelect(event.target.value);
  };

  return (
    <FormControl fullWidth size="small">
      <InputLabel id="dropdown-label">{pickListLable}</InputLabel>
      <Select
        labelId="dropdown-label"
        value={typeId}
        label={pickListLable}
        onChange={handleTypeChange}
      >
        <MenuItem value="">None</MenuItem>
        {dropdownItems.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            {item.title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
