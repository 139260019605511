export const API = process.env.REACT_APP_PRODUCTION
  ? process.env.REACT_APP_API_PRODUCTION
  : process.env.REACT_APP_API_DEVELOPMENT;

export const BASE_URL = process.env.REACT_APP_PRODUCTION
  ? process.env.REACT_APP_API_PRODUCTION
  : process.env.REACT_APP_API_DEVELOPMENT;

export const APP_NAME = process.env.REACT_APP_NAME;

export const DOMAIN = process.env.REACT_APP_PRODUCTION
  ? process.env.REACT_APP_DOMAIN_PRODUCTION
  : process.env.REACT_APP_DOMAIN_DEVELOPMENT;

export const APP_TITLE = "Rajshahi Cadet College";
export const LOGO = "/images/logo/logo.png";
