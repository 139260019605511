import React, { useState } from "react";
import TableContent from "./TableContent";
import ConfirmationModal from "../Modals/ConfirmationModal";

export default function CustomTable(props) {
  const { headCells, rows, handleRemove, handleEdit, isDelete, isEdit } = props;
  const [isAlert, setIsAlert] = useState(false);
  const [removeData, setRemoveData] = useState(null);

  const handleRemoveAlert = (id) => {
    setRemoveData(rows.find((item) => item.id === id));
    setIsAlert(true);
  };

  const handleRemoveDecision = (decision) => (event) => {
    if (decision) handleRemove(removeData.id);
    setIsAlert(false);
    setRemoveData(null);
  };

  return (
    <>
      <TableContent
        headCells={headCells}
        rows={rows}
        handleRemove={handleRemoveAlert}
        handleEdit={handleEdit}
        isDelete={isDelete}
        isEdit={isEdit}
      />

      {isAlert && (
        <ConfirmationModal
          isOpen={isAlert}
          data={removeData}
          headCells={headCells}
          handleDecision={handleRemoveDecision}
        />
      )}
    </>
  );
}
