import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import IconButton from "@mui/material/IconButton";
import Dropdown from "./dropdown";
import { useBookProvider } from "../BookContext";
import { UserAPI } from "../../api";
import { DOMAIN } from "../../config";
import { processAPIError } from "../../api/parent";
import * as actions from "../../constants/actions";

export default function UserForm(props) {
  const { record, setRecord, file, handleFileRecord } = props;
  const { roles, dispatch } = useBookProvider();
  const [addRole, setAddRole] = useState(false);
  const [role, setRole] = useState();
  const [image, setImage] = useState(null);

  useEffect(() => {
    if (record?.image) setImage(`${DOMAIN}/${record?.image}`);
  }, [record]);

  useEffect(() => {
    if (file === null) setImage(null);
  }, [file]);

  const handleRoleSelect = (roleId) => setRecord({ ...record, roleId: roleId });

  const handleChange = (field) => (event) =>
    setRecord({ ...record, [field]: event.target.value });

  const handleFileChange = (event) => {
    handleFileRecord(event.target.files[0]);
    if (event.target.files && event.target.files[0]) {
      setImage(URL.createObjectURL(event.target.files[0]));
    }
  };

  const handleAddRole = async () => {
    try {
      const response = await UserAPI.addRole({ title: role });
      dispatch({ type: actions.ADD_ROLE, payload: response });
      handleRoleSelect(response.id);
      setAddRole(false);
      setRole("");
    } catch (error) {
      dispatch({ type: actions.ERROR, payload: processAPIError(error) });
    }
  };

  return (
    <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={6}>
        <TextField
          fullWidth
          required
          type="string"
          size="small"
          label="User Id"
          variant="outlined"
          value={record?.userid}
          onChange={handleChange("userid")}
        />
      </Grid>
      <Grid item xs={6}>
        <Box sx={{ display: "flex" }}>
          <Dropdown
            defaultValue={record?.roleId}
            dropdownItems={roles}
            handleTypeSelect={handleRoleSelect}
            pickListLable="Role"
          />
          <IconButton
            color="primary"
            aria-label="add user"
            onClick={() => setAddRole(!addRole)}
          >
            <AddCircleOutlineRoundedIcon />
          </IconButton>
        </Box>
        {addRole && (
          <Box sx={{ display: "flex", mt: 1 }}>
            <TextField
              fullWidth
              type="string"
              size="small"
              label="Add Role"
              variant="outlined"
              value={role}
              onChange={(e) => setRole(e.target.value)}
            />
            <IconButton color="primary" size="small" onClick={handleAddRole}>
              <AddRoundedIcon />
            </IconButton>
          </Box>
        )}
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          required
          type="string"
          size="small"
          label="Full Name"
          variant="outlined"
          value={record?.name}
          onChange={handleChange("name")}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          type="string"
          size="small"
          label="Designation"
          variant="outlined"
          value={record?.designation}
          onChange={handleChange("designation")}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          type="string"
          size="small"
          label="Contact Number"
          variant="outlined"
          value={record?.contact_no}
          onChange={handleChange("contact_no")}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          type="email"
          size="small"
          label="Email"
          variant="outlined"
          value={record?.email}
          onChange={handleChange("email")}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          type="string"
          size="small"
          label="House Name"
          variant="outlined"
          value={record?.housename}
          onChange={handleChange("housename")}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          type="string"
          size="small"
          label="Batch No."
          variant="outlined"
          value={record?.batch_no}
          onChange={handleChange("batch_no")}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          type="file"
          size="small"
          label="User Image"
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          onChange={handleFileChange}
        />
      </Grid>

      {image && (
        <Grid item xs={6}>
          <img src={image} alt="preview" width="50" />
        </Grid>
      )}
    </Grid>
  );
}
