import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import * as actions from "../../constants/actions";
import GetUserDialog from "../Common/getUserDialog";
import CustomTable0 from "../Tables/CustomTable0";
import IssueDialog from "./IssueDialog";
import CustomModal2 from "../Modals/CustomModal2";
import SelectedBooksTable from "../Common/SelectedBooksTable";
import utils from "../utils";
import { useBookProvider } from "../BookContext";
import { BookAPI } from "../../api";
import { bookFieldsIssue } from "../../models";
import { loggedInUser } from "../../api/actions";
import { processAPIError } from "../../api/parent";
import { issueFields } from "../../models";
import { DOMAIN } from "../../config";
import Loading from "../Loader/Loading";
import CircularProgress from "@mui/material/CircularProgress";

const EnhancedTableToolbar = (props) => {
  const {
    numSelected,
    numOfBook,
    handleDisplay,
    handleSearch,
    handleClear,
    handleLoadAll,
  } = props;
  const handleSerchKey = (event) => handleSearch(event.target.value);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        p: 2,
      }}
    >
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography variant="subtitle1">
          Available Books <strong>({numOfBook})</strong>
        </Typography>
        <Chip
          label="Load All"
          color="success"
          size="small"
          onClick={handleLoadAll}
        />

        {numSelected > 0 && (
          <>
            <Chip label={numSelected} color="success" size="small" />
            <Chip
              label="Clear"
              color="error"
              size="small"
              onClick={handleClear}
            />
            <Chip
              label="Verify & Issue"
              color="success"
              size="small"
              variant="outlined"
              onClick={handleDisplay}
            />
          </>
        )}
      </Stack>
      <TextField
        sx={{ minWidth: "250px" }}
        size="small"
        label="Enter book id,title or author name"
        onChange={handleSerchKey}
      />
    </Box>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  handleSearch: PropTypes.func.isRequired,
  handleDisplay: PropTypes.func.isRequired,
};

export default function IssueBook() {
  const [rows, setRows] = React.useState([]);
  const [selected, setSelected] = React.useState([]);
  const [showItems, setShowItems] = React.useState(false);
  const [records, setRecords] = React.useState([]);
  const [isOpen, setIsOpen] = React.useState(false);
  const [user, setUser] = React.useState(null);
  const [userIssuedRecords, setIssued] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [issuedLoading, setIssuedLoading] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [viewIssuedBook, setViewIssuedBook] = React.useState(false);
  const authUser = loggedInUser();
  const { dispatch, users, userid, books, getBooks, getUsers, readOnly } =
    useBookProvider();

  const fetchAvailableBooks = async (page_number) => {
    try {
      setLoading(true);
      const response = await BookAPI.getAvailableBooks(page_number);

      const mappBooks = response
        ?.sort(
          (a, b) =>
            parseFloat(a.book_id ? a.book_id : 999999999) -
            parseFloat(b.book_id ? b.book_id : 999999998)
        )
        ?.map((item) => ({
          ...item,
          type_name: item?.type?.title,
        }));

      setRecords(mappBooks);
    } catch (error) {
      dispatch({ type: actions.ERROR, payload: processAPIError(error) });
    } finally {
      setLoading(false);
      return;
    }
  };

  const getIssuedBooksByUserId = async (id) => {
    try {
      setIssuedLoading(true);
      const response = await BookAPI.getIssuedBooksByUserId(id);
      setIssued(
        utils.mappIssuedBook(
          response.filter((item) => !item.return_date && item.active)
        )
      );
    } catch (error) {
      dispatch({ type: actions.ERROR, payload: processAPIError(error) });
    } finally {
      setIssuedLoading(false);
    }
  };

  React.useState(() => {
    if (!books.length) fetchAvailableBooks(page);
  }, [page]);

  React.useEffect(() => {
    setRows(records ? records : []);
  }, [records]);

  React.useEffect(() => {
    const userInfo = users.find((item) => item.userid === userid);
    setUser(userInfo);
    if (userid) getIssuedBooksByUserId(userInfo?.id);
    else setIssued([]);
  }, [userid, users]);

  React.useEffect(() => {
    if (books.length) {
      const mappBooks = books
        ?.filter((item) => !item.written_off && !item.issuedTo)
        ?.map((item) => ({
          ...item,
          type_name: item?.type?.title,
        }));
      setRecords(mappBooks);
    } else {
      setRecords([]);
    }
  }, [books]);

  const handleDisplaySelectedItems = () => {
    setShowItems(true);
  };

  const handleClear = () => setSelected([]);

  const handleSearch = async (key) => {
    if (!key) {
      setRows(records);
      return;
    }

    try {
      setLoading(true);
      const response = await BookAPI.getAvailableBooksBySearch(key);
      const mappBooks = response?.map((item) => ({
        ...item,
        type_name: item.type?.title,
      }));
      setRows(mappBooks);
    } catch (error) {
      dispatch({ type: actions.ERROR, payload: processAPIError(error) });
    } finally {
      setLoading(false);
    }
  };

  const handleIssue = async () => {
    setShowItems(false);

    let user_id;
    if (!users.length) {
      const res_users = await getUsers();
      user_id = res_users.find((item) => item.userid === userid)?.id;
    } else {
      user_id = users.find((item) => item.userid === userid)?.id;
    }

    const issuedData = selected.map((item) => ({
      bookId: item.id,
      userId: user_id,
      issuedById: authUser?.id,
    }));

    try {
      dispatch({ type: actions.LOADING, payload: true });
      const response = await BookAPI.issueBook({
        data: issuedData,
        userid: user_id,
      });

      dispatch({ type: actions.SUCCESS, payload: "Book issued successfully!" });
      setSelected([]);
      if (books.length) {
        dispatch({
          type: actions.ISSUE_BOOKS,
          payload: { data: response, userid: user_id },
        });
      }
    } catch (error) {
      dispatch({ type: actions.ERROR, payload: processAPIError(error) });
    } finally {
      dispatch({ type: actions.LOADING, payload: false });
      getIssuedBooksByUserId(user_id);
    }
  };

  const handleUserSelect = (id) => {
    dispatch({ type: actions.USER_ID, payload: id });
    setIsOpen(false);
  };

  const handleClick = () => {
    setIsOpen(true);
  };

  const handleDisplayIssuedBook = () => setViewIssuedBook(true);
  const closeViewIssuedBookModal = () => setViewIssuedBook(false);

  const handleLoadAll = async () => {
    setLoading(true);
    await getBooks();
    setLoading(false);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ p: 1, mb: 1, boxShadow: "0 1px 1px rgba(0,0,0,.25)" }}>
        <Grid
          container
          spacing={1}
          sx={{ pl: 1, fontSize: "0.875rem" }}
          alignItems="center"
        >
          <Grid item xs={6}>
            {user && (
              <div style={{ display: "flex" }}>
                <Avatar style={{ display: "inline-block", marginRight: "10px" }} src={`${DOMAIN}/${user?.image}`} variant="square" />
                <div>
                  User Info :{" "}
                  <strong>
                    {user?.userid}, {user?.name}
                  </strong>{" "}
                  <br />
                  Total number of issued books :
                  {issuedLoading && (
                    <CircularProgress
                      color="success"
                      size={20}
                      sx={{ ml: 2 }}
                    />
                  )}
                  {!issuedLoading && (
                    <Chip
                      label={userIssuedRecords?.length}
                      color="success"
                      size="small"
                      onClick={handleDisplayIssuedBook}
                    />
                  )}
                </div>
              </div>
            )}
          </Grid>
          <Grid item xs={6} sx={{ textAlign: "right" }}>
            {!readOnly && (
              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={handleClick}
                  sx={{ mr: 1, textTransform: "capitalize" }}
                  disableElevation
                >
                  Change User
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Paper>
      <Paper>
        <EnhancedTableToolbar
          numSelected={selected?.length}
          numOfBook={rows?.length}
          handleDisplay={handleDisplaySelectedItems}
          handleClear={handleClear}
          handleSearch={handleSearch}
          handleLoadAll={handleLoadAll}
        />
        {loading && <Loading />}
        {!loading && (
          <CustomTable0
            headCells={bookFieldsIssue}
            rows={rows}
            selected={selected}
            setSelected={setSelected}
          />
        )}
      </Paper>

      {showItems && (
        <IssueDialog
          isOpen={showItems}
          setIsOpen={setShowItems}
          selected={selected}
          setSelected={setSelected}
          handleIssue={handleIssue}
        />
      )}

      {isOpen && (
        <GetUserDialog
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          handleUserSelect={handleUserSelect}
        />
      )}

      {viewIssuedBook && (
        <CustomModal2
          title="Issued Records"
          size="large"
          open={viewIssuedBook}
          handleClose={closeViewIssuedBookModal}
        >
          <SelectedBooksTable
            records={userIssuedRecords}
            headCells={issueFields}
          />
        </CustomModal2>
      )}
    </Box>
  );
}
