const user = {
  id: "",
  name: "",
  userid: "",
  password: "",
  roleId: "",
  designation: "",
  contact_no: "",
  email: "",
  image: "",
  housename: "",
  batch_no: "",
};

export default user;
