import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import SendIcon from "@mui/icons-material/Send";
import SelectedBooksTable from "../Common/SelectedBooksTable";
import { bookFieldsUser } from "../../models";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function ReturnDialog(props) {
  const {
    isOpen,
    setIsOpen,
    selected,
    setSelected,
    handleReturn,
    handleRenew,
  } = props;
  const [open, setOpen] = React.useState(isOpen);

  const handleClose = () => {
    setOpen(false);
    setIsOpen(false);
  };

  const handleReturnClick = () => {
    handleReturn();
  };

  const handleRenewClick = () => {
    handleRenew();
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="lg"
        fullWidth
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          align="center"
        >
          Verify Book's Information
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <SelectedBooksTable
            isAction={true} 
            headCells={bookFieldsUser} 
            records={selected}
            setRecords={setSelected}/>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center" }}>
          <Button
            onClick={handleClose}
            size="small"
            variant="outlined"
            color="error"
          >
            Cancel
          </Button>
          <Button
            autoFocus
            disableElevation
            size="small"
            variant="contained"
            endIcon={<SendIcon />}
            sx={{ ml: 1 }}
            onClick={handleRenewClick}
          >
            Renew
          </Button>
          <Button
            autoFocus
            disableElevation
            size="small"
            variant="contained"
            endIcon={<SendIcon />}
            sx={{ ml: 1 }}
            onClick={handleReturnClick}
          >
            Return
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
