import * as React from "react";
import styles from "./reportTable.module.css";

export default function ReportTable(props) {
  const { records } = props;
  return (
    <>
      {records.map((record) => (
        <table className={styles.table} key={record.id}>
          <thead className={styles.head}>
            <tr>
              <th colSpan="8">
                {record.title} ({record.total})
              </th>
            </tr>
          </thead>
          <tbody>
            {record.data?.map((items, index) => (
              <tr className={styles.row} key={index}>
                {items.map((item, index2) => (
                  <td key={index2}>{item}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      ))}
    </>
  );
}
