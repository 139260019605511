import React, { useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import Zoom from "@mui/material/Zoom";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import { useBookProvider } from "../BookContext";
import { visuallyHidden } from "@mui/utils";
import { DOMAIN } from "../../config";
import utils from "../utils";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, headCells, readOnly } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {!readOnly && <TableCell padding="checkbox">Actions</TableCell>}

        {headCells.map((headCell) => (
          <TableCell
            variant="head"
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              sx={{ minWidth: headCell.width }}
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

export default function TableContent(props) {
  const { headCells, rows, handleRemove, handleEdit, isDelete, isEdit } = props;
  const { readOnly } = useBookProvider();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleRemoveMode = (id) => (event) => {
    handleRemove(id);
  };

  const handleEditMode = (id) => (event) => {
    handleEdit(id);
  };

  const handleFileOpen = (url) => window.open(DOMAIN + "/" + url, "_blank");

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", boxShadow: "0 0 1px rgba(0,0,0,.25)" }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size="small"
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headCells={headCells}
              readOnly={readOnly}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow hover key={index}>
                      {!readOnly && (
                        <TableCell padding="checkbox">
                          <Stack direction="row" spacing={1}>
                            {isDelete && (
                              <Tooltip
                                title={"Remove " + (row.name || row.title)}
                                placement="top"
                                TransitionComponent={Zoom}
                              >
                                <IconButton
                                  size="small"
                                  color="error"
                                  aria-label="remove"
                                  onClick={handleRemoveMode(row.id)}
                                >
                                  <DeleteIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            )}

                            {isEdit && (
                              <Tooltip
                                title={"Edit " + (row.name || row.title)}
                                placement="top"
                                TransitionComponent={Zoom}
                              >
                                <IconButton
                                  size="small"
                                  color="primary"
                                  aria-label="edit"
                                  onClick={handleEditMode(row.id)}
                                >
                                  <ModeEditIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            )}
                          </Stack>
                        </TableCell>
                      )}

                      {headCells.map((cell, index) => (
                        <TableCell
                          key={index}
                          id={cell.id}
                          align={cell.numeric ? "right" : "left"}
                          padding={cell.disablePadding ? "none" : "normal"}
                        >
                          {cell?.type !== "date" &&
                            cell?.type !== "file" &&
                            cell?.type !== "image" && <>{row[cell.id]}</>}

                          {cell?.type === "date" && (
                            <>{utils.dateFormated(row[cell.id])}</>
                          )}

                          {cell?.type === "file" && (
                            <Tooltip
                              title="Open File"
                              placement="top"
                              TransitionComponent={Zoom}
                            >
                              <IconButton
                                size="small"
                                color="primary"
                                aria-label="File"
                                onClick={() => handleFileOpen(row[cell.id])}
                              >
                                <FileOpenIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          )}

                          {cell?.type === "image" && (
                            <Avatar
                              src={`${DOMAIN}/${row[cell.id]}`}
                              variant="square"
                            />
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 33 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100, 500, 1000]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}
