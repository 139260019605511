import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LandingPage from "./components/LandingPage";
import Error from "./components/Error";
import Home from "./components/Home";
import User from "./components/User";
import Book from "./components/Book";
import ReturnBook from "./components/ReturnBook";
import IssuedBooks from "./components/IssuedBooks";
import StockTaking from "./components/StockTaking";
import WrittenOffBooks from "./components/WrittenOffBooks";
import Ebook from "./components/Ebook";
import Notice from "./components/Notice";
import Setting from "./components/Setting";
import IssueBook from "./components/IssueBook";
import SignUp from "./components/Auth/SignUp";
import ProtectedRoutes from "./components/ProtectedRoutes";
import IssueBookById from "./components/IssueBookByUser/issueBookById";
import ReturnBookById from "./components/IssueBookByUser/returnBookById";
import IssueBookByUser from "./components/IssueBookByUser";
import Profile from "./components/Profile";
import SignIn from "./components/Auth/SignIn";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LandingPage />}>
            <Route path="signin" element={<SignIn />} />
            <Route path="issue-return/:id" element={<IssueBookByUser />} />
          </Route>
          <Route path="signup" element={<SignUp />} />
          <Route element={<ProtectedRoutes />}>
            <Route path="library" element={<Home />}>
              <Route index element={<Book />} />
              <Route path="users" element={<User />} />
              <Route path="books" element={<Book />} />
              <Route path="issue-book/:id" element={<IssueBookById />} />
              <Route path="return-book/:id" element={<ReturnBookById />} />
              <Route path="issue-book" element={<IssueBook />} />
              <Route path="return-book" element={<ReturnBook />} />
              <Route path="issued-books" element={<IssuedBooks />} />
              <Route path="stock-taking" element={<StockTaking />} />
              <Route path="written-off-books" element={<WrittenOffBooks />} />
              <Route path="ebook" element={<Ebook />} />
              <Route path="notice" element={<Notice />} />
              <Route path="setting" element={<Setting />} />
              <Route path="profile" element={<Profile />} />
            </Route>
          </Route>
          <Route path="*" element={<Error code="404" message="Not Found" />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
