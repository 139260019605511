import { apiInstance, processApiResponse } from "./parent";
const NoticeAPI = {
  async getNotices() {
    const path = "/notice/all";
    const response = await apiInstance.get(path);
    return processApiResponse(response);
  },

  async getNotice(id) {
    const path = "/notice/" + id;
    const response = await apiInstance.get(path);
    return processApiResponse(response);
  },

  async addNotice(data) {
    const path = "/notice";
    const response = await apiInstance.post(path, data);
    return processApiResponse(response);
  },

  async updateNotice(data) {
    const path = "/notice/update";
    const response = await apiInstance.post(path, data);
    return processApiResponse(response);
  },

  async removeNotice(data) {
    const path = "/notice/remove";
    const response = await apiInstance.post(path, data);
    return processApiResponse(response);
  },
};

export default NoticeAPI;
