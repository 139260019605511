import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import ClearIcon from "@mui/icons-material/Clear";
import utils from "../utils";

export default function SelectedBooksTable(props) {
  const { records, setRecords, isAction, headCells } = props;

  const handleRemove = (id) => () => {
    setRecords(records.filter((item) => item.id !== id));
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {isAction && <TableCell sx={{ p: 2 }}>Action</TableCell>}

            {headCells.map((cell, index) => (
              <TableCell
                key={index}
                id={cell.id}
                align={cell.numeric ? "right" : "left"}
                sx={{ width: cell.width }}
              >
                {cell.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {records.map((row) => (
            <TableRow
              key={row.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              {isAction && (
                <TableCell align="center">
                  <Tooltip title="remove book">
                    <IconButton color="error" onClick={handleRemove(row.id)}>
                      <ClearIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              )}

              {headCells.map((cell, index) => (
                <TableCell
                  key={index}
                  align={cell.numeric ? "right" : "left"}
                  padding={cell.disablePadding ? "none" : "normal"}
                >
                  {cell?.type === "date"
                    ? utils.dateFormated(row[cell.id])
                    : row[cell.id]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
