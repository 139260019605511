import React from "react";
import { Link } from "react-router-dom";
import "./error.scss";

const Error = ({ code, message, buttonText = "Home", link = "/" }) => {
  return (
    <div>
      <h5>{code}</h5>
      <h1>{message}</h1>
      <Link to={link} className="home">
        Go {buttonText}
      </Link>
    </div>
  );
};

export default Error;
