import * as React from "react";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { removeCookie, removeLocalStorage } from "../../api/actions";
import { APP_TITLE, LOGO } from "../../config";
import { background } from "../../constants/parameters";

const CustomAppBar = () => {
  const navigate = useNavigate();
  const handleProfile = () => {
    navigate("/library/profile");
  };

  const handleLogOut = () => {
    removeCookie("token");
    removeLocalStorage("user");
    navigate("/");
  };

  const AppBar = styled(MuiAppBar)(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    boxShadow: "0 1px 0 rgba(0, 0, 0, 0.12)",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  }));

  return (
    <AppBar position="fixed" sx={{ backgroundColor: background }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters variant="dense">
          <Avatar
            alt="logo"
            src={LOGO}
            sx={{ width: 34, height: 34 }}
          />
          <Box sx={{ ml: "5px" }}>
            <Typography
              variant="body1"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                mt: "4px",
                display: { xs: "none", md: "flex" },
                fontWeight: 700,
                color: "inherit",
                textDecoration: "none",
                lineHeight: "1.25",
              }}
            >
              Library Management System
            </Typography>
            <Typography variant="body2" sx={{ fontSize: "0.75rem" }}>
              {APP_TITLE}
            </Typography>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}></Box>

          <Box sx={{ flexGrow: 0 }}>
            <Button color="inherit" onClick={handleProfile}>
              Profile
            </Button>
            <Button color="inherit" onClick={handleLogOut}>
              Log Out
            </Button>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default CustomAppBar;
