import { useContext } from "react";
import BookContext from "./BookContext";

const useBookProvider = () => {
  const {
    books,
    bookTypes,
    users,
    roles,
    issuedBooks,
    userid,
    dispatch,
    getBooks,
    getIssuedBooks,
    getUsers,
    readOnly,
  } = useContext(BookContext);

  return {
    books,
    bookTypes,
    users,
    roles,
    issuedBooks,
    userid,
    dispatch,
    getBooks,
    getIssuedBooks,
    getUsers,
    readOnly,
  };
};

export default useBookProvider;
