import * as React from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import CustomAppBar from "../CustomAppBar";
import CustomDrawer from "../CustomDrawer";
import Loader from "../Loader";
import Alert from "../Alert";
import { ThemeProvider } from "@mui/material/styles";
import { BookContext } from "../BookContext";
import { globalTheme } from "../Theme";
import { BookAPI, UserAPI } from "../../api";
import { loggedInUser } from "../../api/actions";
import { processAPIError } from "../../api/parent";
import * as actions from "../../constants/actions";

export default function Home(props) {
  const [loading, setLoading] = React.useState(false);
  const [users, setUsers] = React.useState([]);
  const [books, setBooks] = React.useState([]);
  const [bookTypes, setBookTypes] = React.useState([]);
  const [issuedBooks, setIssuedBooks] = React.useState([]);
  const [roles, setRoles] = React.useState([]);
  const [userid, setUserId] = React.useState();
  const [readOnly, setReadOnly] = React.useState(true);
  const isLibrarian = loggedInUser().librarian;
  const [alert, setAlert] = React.useState({
    success: false,
    error: false,
    message: "",
  });

  React.useEffect(() => {
    setReadOnly(isLibrarian ? false : true);
    if (!isLibrarian) setUserId(loggedInUser().userid);
  }, [isLibrarian]);

  React.useEffect(() => {
    (async () => {
      setLoading(true);

      const responses = await Promise.allSettled([
        BookAPI.getBookTypes(),
        UserAPI.getRoles(),
        UserAPI.getUsers(),
      ]);

      const typesResponse =
        responses[0].status === "fulfilled" ? responses[0].value : [];
      const rolesResponse =
        responses[1].status === "fulfilled" ? responses[1].value : [];
      const usersResponse =
        responses[2].status === "fulfilled" ? responses[2].value : [];

      setBookTypes(typesResponse);
      setRoles(rolesResponse);
      setUsers(usersResponse.filter((item) => item.active));

      setLoading(false);

      const errors = [];
      responses.forEach((item) => {
        if (item.status !== "fulfilled") errors.push(item.reason.message);
      });

      if (errors.length > 0) handleError(errors);
    })();
  }, []);

  const handleError = (message) =>
    setAlert({ success: false, error: true, message: message });

  const handleSuccess = (message) =>
    setAlert({ success: true, error: false, message: message });

  const getBooks = async (field) => {
    try {
      const response = await BookAPI.getBooks();
      setBooks(
        response?.sort((a, b) => parseFloat(a.book_id) - parseFloat(b.book_id))
      );
      return;
    } catch (error) {
      handleError(processAPIError(error));
      return;
    }
  };

  const getIssuedBooks = async () => {
    try {
      const response = await BookAPI.getIssuedBooks();
      setIssuedBooks(response);
    } catch (error) {
      handleError(processAPIError(error));
    } finally {
      return;
    }
  };

  const getUsers = async () => {
    try {
      const response = await UserAPI.getUsers();
      setUsers(response.filter((item) => item.active));
      return response;
    } catch (error) {
      handleError(processAPIError(error));
      return [];
    }
  };

  const dispatch = (action) => {
    const { type, payload } = action;
    switch (type) {
      case actions.ERROR: {
        handleError(payload);
        break;
      }

      case actions.SUCCESS: {
        handleSuccess(payload);
        break;
      }

      case actions.ALERT: {
        setAlert({ success: payload.status, message: payload.message });
        break;
      }

      case actions.USER_ID: {
        setUserId(payload);
        break;
      }

      case actions.LOADING: {
        setLoading(payload);
        break;
      }

      case actions.ISSUE_BOOKS: {
        const data = payload.data;
        const user_id = payload.userid;

        const mappIssuedBook = data.map((item) => ({
          ...item,
          book: books.find((book) => book.id === item.bookId),
          issuedBy: users.find((user) => user.id === item.issuedById),
          user: users.find((user) => user.id === item.userId),
          return_date: null,
          renew_date: null,
          returnBy: null,
          returnById: null,
        }));

        const sortedRecords = issuedBooks
          .concat(mappIssuedBook)
          .sort(
            (a, b) => Date.parse(b.issued_date) - Date.parse(a.issued_date)
          );

        setIssuedBooks(sortedRecords);

        const bookIds = data.map((item) => item.bookId);
        const updateBooks = books.map((item) =>
          bookIds.includes(item.id)
            ? {
                ...item,
                issuedTo: user_id,
                user: users.find((user) => user.id === user_id),
              }
            : item
        );
        setBooks(updateBooks);
        break;
      }

      case actions.RENEW_BOOKS: {
        const issuedIds = payload.map((item) => item.id);
        const updateIssuedBooks = issuedBooks.map((item) => {
          if (issuedIds.includes(item.id)) {
            const issuedBook = payload.find((item2) => item2.id === item.id);
            return {
              ...item,
              renew_date: issuedBook.renew_date,
              updatedAt: issuedBook.updatedAt,
            };
          } else return item;
        });
        setIssuedBooks(updateIssuedBooks);
        handleSuccess("Book renewed successfully!");
        break;
      }

      case actions.RETURN_BOOKS: {
        const issuedIds = payload.map((item) => item.id);
        const bookIds = payload.map((item) => item.bookId);
        const updateIssuedBooks = issuedBooks.map((item) => {
          const issuedBook = payload.find((item2) => item2.id === item.id);
          if (issuedIds.includes(item.id)) {
            return {
              ...item,
              return_date: issuedBook.return_date,
              returnById: issuedBook.returnById,
              updatedAt: issuedBook.updatedAt,
            };
          } else return item;
        });

        const updateBooks = books.map((item) =>
          bookIds.includes(item.id)
            ? {
                ...item,
                issuedTo: null,
                user: null,
              }
            : item
        );
        setIssuedBooks(updateIssuedBooks);
        setBooks(updateBooks);
        break;
      }

      case actions.ADD_USER: {
        const response = {
          ...payload,
          role: roles.find((item) => item.id === payload.roleId),
        };
        setUsers([...users, response]);
        handleSuccess("User added successfully!");
        break;
      }

      case actions.EDIT_USER: {
        const updateUsers = users.map((item) => {
          if (item.id === parseInt(payload.id)) return payload;
          else return item;
        });
        setUsers(updateUsers);
        break;
      }

      case actions.REMOVE_USER: {
        setUsers(users.filter((item) => item.id !== payload));
        handleSuccess("User removed successfully!");
        break;
      }

      case actions.ADD_ROLE: {
        setRoles([...roles, payload]);
        break;
      }

      case actions.ADD_TYPE: {
        setBookTypes([...bookTypes, payload]);
        break;
      }

      case actions.ADD_BOOK: {
        setBooks([...books, payload]);
        break;
      }

      case actions.REMOVE_BOOK: {
        const updatedBooks = books.map((item) => {
          if (item.id === payload) return { ...item, written_off: true };
          else return item;
        });
        setBooks(updatedBooks);
        handleSuccess("Book removed successfully!");
        break;
      }

      case actions.UPDATE_BOOK: {
        const updatedBooks = books.map((item) => {
          if (item.id === payload.id)
            return {
              ...payload,
              type: bookTypes.find((type) => type.id === payload.typeId),
            };
          else return item;
        });
        setBooks(updatedBooks);
        break;
      }

      case actions.DELETE_BOOK: {
        setBooks(books.filter((item) => !payload.includes(item.id)));
        break;
      }

      case actions.ROLLBACK_BOOKS: {
        const bookIds = payload.map((item) => item.id);
        const updatedBooks = books.map((item) => {
          if (bookIds.includes(item.id)) {
            return {
              ...item,
              written_off: false,
            };
          } else return item;
        });
        setBooks(updatedBooks);
        break;
      }

      case actions.REMOVE_ISSUED_BOOK: {
        const updatedIssuedBooks = issuedBooks.map((item) => {
          if (item.id === payload) return { ...item, active: false };
          else return item;
        });
        setIssuedBooks(updatedIssuedBooks);
        break;
      }

      default:
        console.log("default case");
    }
  };

  return (
    <BookContext.Provider
      value={{
        books: books,
        bookTypes: bookTypes,
        users: users,
        roles: roles,
        issuedBooks: issuedBooks,
        userid: userid,
        readOnly: readOnly,
        dispatch,
        getBooks,
        getIssuedBooks,
        getUsers,
      }}
    >
      <ThemeProvider theme={globalTheme}>
        <Box sx={{ display: "flex" }}>
          <CssBaseline />

          <CustomAppBar />

          <CustomDrawer />
        </Box>

        <Loader open={loading} setOpen={setLoading} />

        <Alert alert={alert} />
      </ThemeProvider>
    </BookContext.Provider>
  );
}
