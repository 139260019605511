import bookIdGenerator from "./bookIdGenerator";
import dataListGenerator from "./dataListGenerator";
import utils from "../components/utils";
import "./kalpurush-normal";

const services = {
  stockTaking(records) {
    bookIdGenerator(records, "Available Books");
  },

  writtenOffTaking(records) {
    bookIdGenerator(records, "Written Off Books");
  },

  allbooks(books, bookTypes) {
    const columns = [
      { header: "Book Id", dataKey: "book_id" },
      { header: "Title", dataKey: "title" },
      { header: "Author", dataKey: "author" },
      { header: "Type", dataKey: "type" },
      { header: "Publisher", dataKey: "publisher" },
      { header: "Publication Year", dataKey: "publication_year" },
      { header: "Edition", dataKey: "edition" },
      { header: "Shelf No", dataKey: "shelf_no" },
      { header: "Price", dataKey: "price" },
      { header: "Pages", dataKey: "pages" },
      { header: "Note", dataKey: "note" },
    ];

    const columnStyles = {
      book_id: {
        font: "kalpurush",
      },
      title: {
        font: "kalpurush",
      },
      author: {
        font: "kalpurush",
      },
      type: {
        font: "kalpurush",
      },
      publisher: {
        font: "kalpurush",
      },
      publication_year: {
        font: "kalpurush",
      },
      edition: {
        font: "kalpurush",
      },
      shelf_no: {
        font: "kalpurush",
      },
      price: {
        halign: "right",
      },
      pages: {
        font: "kalpurush",
      },
      note: {
        font: "kalpurush",
      },
    };

    const mappedBooks = bookTypes?.map((type) => {
      return {
        ...type,
        columns: columns,
        body: books
          ?.filter((item) => item.typeId === type.id)
          ?.map((item) => ({
            book_id: item.book_id,
            title: item.title,
            author: item.author,
            type: item.type?.title,
            publisher: item.publisher,
            publication_year: item.publication_year,
            edition: item.edition,
            shelf_no: item.shelf_no,
            price: item.price,
            pages: item.pages,
            note: item.note,
          })),
      };
    });

    if (mappedBooks.length)
      dataListGenerator(mappedBooks, "AllBooks", columnStyles);
  },

  issuedReport(current, previous) {
    const mapCurrent = current.map((item) => ({
      book_id: item.book_id,
      title: item.book?.title,
      author: item.book?.author,
      type: item?.type,
      user_name: item.user_name,
      issued_date: utils.dateToMMDDYYYY(item.issued_date),
      renew_date: utils.dateToMMDDYYYY(item.renew_date),
      return_date: utils.dateToMMDDYYYY(item.return_date),
      issuedByName: item.issuedByName,
      returnByName: item.returnByName,
    }));

    const mapPrevious = previous.map((item) => ({
      book_id: item.book_id,
      title: item.book?.title,
      author: item.book?.author,
      type: item?.type,
      user_name: item.user_name,
      issued_date: utils.dateToMMDDYYYY(item.issued_date),
      renew_date: utils.dateToMMDDYYYY(item.renew_date),
      return_date: utils.dateToMMDDYYYY(item.return_date),
      issuedByName: item.issuedByName,
      returnByName: item.returnByName,
    }));

    const columns = [
      { header: "Book Id", dataKey: "book_id" },
      { header: "Title", dataKey: "title" },
      { header: "Author", dataKey: "author" },
      { header: "Type", dataKey: "type" },
      { header: "Issued To", dataKey: "user_name" },
      { header: "Issue Date", dataKey: "issued_date" },
      { header: "Renew Date", dataKey: "renew_date" },
      { header: "Return Date", dataKey: "return_date" },
      { header: "Issued By", dataKey: "issuedByName" },
      { header: "Return By", dataKey: "returnByName" },
    ];

    const columnStyles = {
      book_id: {
        font: "kalpurush",
      },
      title: {
        font: "kalpurush",
      },
      author: {
        font: "kalpurush",
      },
      type: {
        font: "kalpurush",
      },
      user_name: {
        font: "kalpurush",
      },
      issued_date: {
        halign: "right",
      },
      renew_date: {
        halign: "right",
      },
      return_date: {
        halign: "right",
      },
    };
    const data = [
      {
        title: "Currently issued books",
        columns: columns,
        body: mapCurrent,
      },
      {
        title: "Previously issued books",
        columns: columns,
        body: mapPrevious,
      },
    ];
    if (mapCurrent.length || mapPrevious.length)
      dataListGenerator(data, "IssuedBooksPerUser", columnStyles);
  },

  allusers(records, roles) {
    const columns = [
      { header: "User Id", dataKey: "userid" },
      { header: "Name", dataKey: "name" },
      { header: "Role", dataKey: "role" },
      { header: "Designation", dataKey: "designation" },
      { header: "Contact Number", dataKey: "contact_no" },
      { header: "Email", dataKey: "email" },
      { header: "House Name", dataKey: "housename" },
      { header: "Batch Number", dataKey: "batch_no" },
    ];

    const columnStyles = {
      userid: {
        font: "kalpurush",
      },
      name: {
        font: "kalpurush",
      },
      role: {
        font: "kalpurush",
      },
      designation: {
        font: "kalpurush",
      },
      contact_no: {
        font: "kalpurush",
      },
      email: {
        font: "kalpurush",
      },
      housename: {
        font: "kalpurush",
      },
      batch_no: {
        font: "kalpurush",
      },
    };

    const mappedRecords = roles?.map((role) => {
      return {
        ...role,
        title: role.title?.toUpperCase(),
        columns: columns,
        body: records
          ?.filter((item) => item.roleId === role.id)
          ?.map((item) => ({
            userid: item.userid,
            name: item.name,
            designation: item.designation,
            role: item.role?.title,
            contact_no: item.contact_no,
            email: item.email,
            housename: item.housename,
            batch_no: item.batch_no,
          })),
      };
    });

    if (mappedRecords.length)
      dataListGenerator(mappedRecords, "AllUsers", columnStyles);
  },
};

export default services;
