import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style.css";

export default function CustomSlider() {
  const settings = {
    dots: true,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  return (
    <div style={{ margin: "-12px -8px" }}>
      <Slider {...settings}>
        <div className="image">
          <img
            style={{ width: "100%" }}
            src="/images/slider/image1.jpg"
            alt="image1"
          />
        </div>
        <div className="image">
          <img
            style={{ width: "100%" }}
            src="/images/slider/image2.jpg"
            alt="image2"
          />
        </div>
        <div className="image">
          <img
            style={{ width: "100%" }}
            src="/images/slider/image3.jpg"
            alt="image3"
          />
        </div>
        <div className="image">
          <img
            style={{ width: "100%" }}
            src="/images/slider/image4.jpg"
            alt="image4"
          />
        </div>
        <div className="image">
          <img
            style={{ width: "100%" }}
            src="/images/slider/image5.jpg"
            alt="image5"
          />
        </div>
        <div className="image">
          <img
            style={{ width: "100%" }}
            src="/images/slider/image6.jpg"
            alt="image6"
          />
        </div>
        <div className="image">
          <img
            style={{ width: "100%" }}
            src="/images/slider/image7.jpg"
            alt="image7"
          />
        </div>
      </Slider>
    </div>
  );
}
