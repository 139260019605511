import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import CustomTable0 from "../Tables/CustomTable0";
import ReturnDialog from "./returnDialog";

const EnhancedTableToolbar = (props) => {
  const { numSelected, handleDisplay, handleClear, numOfIssuedBook } = props;

  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      sx={{ pl: 2, pb: 1, pt: 1 }}
    >
      <Typography variant="subtitle1">
        Currently Issued Books <strong>({numOfIssuedBook})</strong>
      </Typography>
      {numSelected > 0 && (
        <>
          <Chip label={numSelected} color="success" size="small" />
          <Chip
            label="Clear"
            color="error"
            size="small"
            onClick={handleClear}
          />
          <Chip
            label="Verify & Return"
            color="success"
            size="small"
            variant="outlined"
            onClick={handleDisplay}
          />
        </>
      )}
    </Stack>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function IssuedBookList(props) {
  const { headCells, records, handleReturn, handleRenew } = props;
  const [rows, setRows] = React.useState([]);
  const [selected, setSelected] = React.useState([]);
  const [showItems, setShowItems] = React.useState(false);

  React.useEffect(() => {
    setRows(records ? records : []);
  }, [records]);

  const handleDisplaySelectedItems = () => {
    setShowItems(true);
  };

  const handleClear = () => setSelected([]);

  const handleRenewClick = () => {
    handleRenew(selected);
    setShowItems(false);
    setSelected([]);
  };

  const handleReturnClick = () => {
    handleReturn(selected);
    setShowItems(false);
    setSelected([]);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          handleDisplay={handleDisplaySelectedItems}
          handleClear={handleClear}
          numOfIssuedBook={rows?.length}
        />
        <CustomTable0
          headCells={headCells}
          rows={rows}
          selected={selected}
          setSelected={setSelected}
        />
      </Paper>
      {showItems && (
        <ReturnDialog
          isOpen={showItems}
          setIsOpen={setShowItems}
          selected={selected}
          setSelected={setSelected}
          handleReturn={handleReturnClick}
          handleRenew={handleRenewClick}
        />
      )}
    </Box>
  );
}
