const Paths = {
  LANDING_PAGE: () => "/",
  BOOK_DETAILS: (id) => `/library/${id}`,
  HOME: () => "/library",
  LIBRARY: () => "/library",
  USERS: () => "/library/users",
  BOOKS: () => "/library/books",
  ISSUE_BOOK: () => "/library/issue-book/",
  ISSUE_BOOK_BY_ID: (id) => `/library/issue-book/${id}`,
  RETURN_BOOK_BY_ID: (id) => `/library/return-book/${id}`,
  BOOKS_BY_TYPE: (typeid) => `/library/book-by-type/${typeid}`,
  RETURN_BOOK: (id) => "/library/return-book",
  ISSUED_BOOKS: () => "/library/issued-books",
  STOCK_TAKING: (appId) => "/library/stock-taking",
  WRITTEN_OFF_BOOKS: () => "/library/written-off-books",
  EBOOK: () => "/library/ebook",
  NOTICE: () => "/library/notice",
  SETTING: () => "/library/setting",
  PROFILE: () => "/library/profile",
};

export default Paths;
