import * as React from "react";
import { experimentalStyled as styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { useBookProvider } from "../../BookContext";
import { Typography } from "@mui/material";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  cursor: "pointer",
  ":hover": {
    color: "green",
    textDecoration: "underline",
  },
}));

export default function BookByTypes() {
  const { books, bookTypes } = useBookProvider();
  const [types, setTypes] = React.useState([]);
  const [writtenOffBooks, setWrittenOffBooks] = React.useState([]);

  React.useEffect(() => {
    const writtenBooks = books.filter((item) => item.written_off);
    setWrittenOffBooks(writtenBooks);

    const mappedTypes = bookTypes.map((item) => ({
      id: item.id,
      title: item.title,
      total: writtenBooks.filter((book) => book.typeId === item.id)?.length,
    }));
    setTypes(mappedTypes);
  }, [bookTypes, books]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
        <Grid item xs={12} sx={{ textAlign: "center" }}>
          <Typography variant="h6">
            Total Written Off Books : {writtenOffBooks.length}
          </Typography>
        </Grid>
        {types.map((type, index) => (
          <Grid item xs={2} sm={4} md={4} key={index}>
            <Item elevation={2}>
              {type.title}({type.total})
            </Item>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
