import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import { loggedInUser } from "../../api/actions";
import { useBookProvider } from "../BookContext";
import { userFields } from "../../models";

export default function About() {
  const { users } = useBookProvider();
  const [user, setUser] = useState({});
  const [fields, setFields] = useState([]);

  useEffect(() => {
    const loggedIn = loggedInUser()?.userid;
    const userInfo = users.find((item) => item.userid === loggedIn);
    setUser(userInfo ? { ...userInfo, role_name: userInfo?.role?.title } : {});

    if (userInfo?.role_name?.toLowercase() === "student")
      setFields(userFields.student);
    else if (userInfo?.role_name?.toLowercase() === "other")
      setFields(userFields.all);
    else setFields(userFields.teacher);
  }, [users]);

  return (
    <Card sx={{ maxWidth: 500, margin: "0 auto" }}>
      <CardContent>
        <Grid sx={{ textAlign: "center" }}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Personal Information
          </Typography>
        </Grid>
        <Divider />
        <TableContainer>
          <Table size="small">
            <TableBody>
              {fields.map((cell, index) => (
                <TableRow key={index}>
                  <TableCell variant="head" align="right">
                    {cell.label} :
                  </TableCell>
                  <TableCell variant="body" align="left">
                    {user[cell.id]}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
}
