import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import DropdownAsyncSearch from "../Form/DropdownAsyncSearch";
import { styled } from "@mui/material/styles";
import { useBookProvider } from "../BookContext";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const initValue = {
  bookId: "",
  title: "",
  author: "",
  type: "",
  edition: "",
  note: "",
};

export default function EbookFormDialog(props) {
  const { open, handleSubmit, handleCancel, ebook, isEdit } = props;
  const { books } = useBookProvider();
  const [record, setRecord] = React.useState(initValue);
  const [options, setOptions] = React.useState([]);
  const [selectedFile, setSelectedFile] = React.useState(null);

  React.useState(() => {
    setRecord(ebook);
  }, [ebook]);

  const handleChange = (field) => (event) => {
    setRecord({ ...record, [field]: event.target.value });
  };

  const handleSubmitData = () => handleSubmit(record, selectedFile);

  React.useEffect(() => {
    const mappData = books
      .filter((item) => !item.written_off)
      .map((item) => ({
        value: item.id,
        label: item.title,
        book_id: item.book_id,
      }));
    setOptions(mappData);
  }, [books]);

  const handleBookSelection = (id) => {
    const data = books.find((item) => item.id === id);

    setRecord({
      ...record,
      bookId: data.id,
      title: data.title ? data.title : "",
      author: data.author ? data.author : "",
      type: data.type ? data.type?.title : "",
      edition: data.edition ? data.edition : "",
    });
  };

  const handleFileChange = (event) => setSelectedFile(event.target.files[0]);

  return (
    <div>
      <BootstrapDialog
        onClose={handleCancel}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="md"
        fullWidth
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleCancel}
          align="center"
        >
          {isEdit ? "Edit" : "Add"}
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Grid
            container
            rowSpacing={2}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={6} sx={{ position: "relative", mt: 1 }}>
              <label
                style={{
                  fontSize: " 0.75rem",
                  position: "absolute",
                  top: 0,
                  color: "rgba(0, 0, 0, 0.6)",
                }}
              >
                Select Book
              </label>
              <DropdownAsyncSearch
                options={options}
                handleSelectOption={handleBookSelection}
                defaultValue={record.bookId ? record.bookId : null}
              />
            </Grid>
            <Grid item xs={6} sx={{ mt: 1 }}>
              <TextField
                fullWidth
                type="string"
                size="small"
                label="Book Id"
                variant="outlined"
                value={record.bookId ? record.bookId : ""}
                disabled
              />
            </Grid>
            <Grid item xs={6} sx={{ mt: 1 }}>
              <TextField
                fullWidth
                type="string"
                size="small"
                label="Book Type"
                variant="outlined"
                value={record.type ? record.type : ""}
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                type="string"
                size="small"
                label="Book Title"
                variant="outlined"
                value={record.title ? record.title : ""}
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                type="string"
                size="small"
                label="Edition"
                variant="outlined"
                value={record.edition ? record.edition : ""}
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                type="string"
                size="small"
                label="Author"
                variant="outlined"
                value={record.author ? record.author : ""}
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                type="file"
                size="small"
                label="File"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={handleFileChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                size="small"
                label="Note"
                variant="outlined"
                multiline
                rows={4}
                value={record.note ? record.note : ""}
                onChange={handleChange("note")}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center" }}>
          <Button
            onClick={handleCancel}
            size="small"
            variant="outlined"
            color="error"
          >
            Cancel
          </Button>
          <Button
            autoFocus
            disableElevation
            size="small"
            variant="contained"
            sx={{ ml: 1 }}
            onClick={handleSubmitData}
          >
            Save
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
