import React from "react";
import Paper from "@mui/material/Paper";
import * as actions from "../../../constants/actions";
import AddBookForm from "../../Form/AddBookForm";
import utils from "../../utils";
import { BookAPI } from "../../../api";
import { useBookProvider } from "../../BookContext";
import { processAPIError } from "../../../api/parent";

export default function AddBook(props) {
  const { handleTabSwitch } = props;
  const { dispatch, bookTypes } = useBookProvider();

  const handleSubmit = async (record) => {
    const validMessage = utils.validateBook(record);
    if (validMessage) {
      dispatch({ type: actions.ERROR, payload: validMessage });
      return false;
    }

    try {
      dispatch({ type: actions.LOADING, payload: true });
      const response = await BookAPI.addBook(record);
      const payload = {
        ...response,
        type: bookTypes.find((item) => item.id === response.typeId),
      };
      dispatch({ type: actions.ADD_BOOK, payload: payload });
      dispatch({ type: actions.SUCCESS, payload: "Book Added Successfully." });
      return true;
    } catch (error) {
      dispatch({ type: actions.ERROR, payload: processAPIError(error) });
      return false;
    } finally {
      dispatch({ type: actions.LOADING, payload: false });
    }
  };

  const handleCancel = () => handleTabSwitch(0, "");

  return (
    <Paper elevation={3} sx={{ p: 3 }}>
      <AddBookForm
        handleSubmitRecord={handleSubmit}
        handleCancel={handleCancel}
      />
    </Paper>
  );
}
