import * as React from "react";
import BookTypes from "./BookTypes";
import AllBook from "./AllBook";
import AddBook from "./AddBook";
import CustomTab from "../CustomTab";
import { useBookProvider } from "../BookContext";
import Loading from "../Loader/Loading";

export default function Book() {
  const { readOnly, getBooks, books } = useBookProvider();
  const [tab, setTab] = React.useState(0);
  const [bookTypeId, setBookTypeId] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    (async () => {
      if (!books.length) {
        setLoading(true);
        await getBooks();
        setLoading(false);
      }
    })();
  }, []);

  const tabItems0 = [
    {
      title: "All Book",
      index: 0,
      component: () => <AllBook bookTypeId={bookTypeId} />,
    },
    {
      title: "Book Types",
      index: 1,
      component: () => <BookTypes handleTabSwitch={handleTabSwitch} />,
    },
  ];

  let tabItems = tabItems0;
  if (!readOnly)
    tabItems = [
      ...tabItems0,
      {
        title: "Add Book",
        index: 2,
        component: () => <AddBook handleTabSwitch={handleTabSwitch} />,
      },
    ];

  const handleTabSwitch = (tabId, typeId) => {
    setTab(tabId);
    setBookTypeId(typeId);
  };

  const handleTabChange = (value) => {
    setTab(value);
    setBookTypeId("");
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <CustomTab
          defaultTab={tab}
          tabs={tabItems}
          handleTabChange={handleTabChange}
        />
      )}
    </>
  );
}
