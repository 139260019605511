import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function NoticeFormDialog(props) {
  const { open, notice, isEdit, handleSubmit, handleCancel } = props;
  const [record, setRecord] = React.useState({});
  const [selectedFile, setSelectedFile] = React.useState(null);

  React.useState(() => {
    setRecord(notice);
  }, [notice]);

  const handleChange = (field) => (event) => {
    setRecord({ ...record, [field]: event.target.value });
  };

  const handleSubmitData = () => handleSubmit(record, selectedFile);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleCancel}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="sm"
        fullWidth
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleCancel}
          align="center"
        >
          {isEdit ? "Edit" : "Add"} Notice
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Grid
            container
            rowSpacing={2}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={12}>
              <TextField
                fullWidth
                type="string"
                size="small"
                label="Title"
                variant="outlined"
                value={record.title}
                onChange={handleChange("title")}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                type="file"
                size="small"
                label="File"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={handleFileChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                size="small"
                label="Note"
                variant="outlined"
                multiline
                rows={4}
                value={record.note}
                onChange={handleChange("note")}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center" }}>
          <Button
            onClick={handleCancel}
            size="small"
            variant="outlined"
            color="error"
          >
            Cancel
          </Button>
          <Button
            autoFocus
            disableElevation
            size="small"
            variant="contained"
            sx={{ ml: 1 }}
            onClick={handleSubmitData}
          >
            Save
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
