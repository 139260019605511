import React, { useEffect, useState } from "react";
import CustomTab from "../CustomTab";
import AllWrittenOffBook from "./AllWrittenOffBook";
import BookByTypes from "./BookByTypes";
import ReportWrittenOffBooks from "./ReportWrittenOffBooks";
import { useBookProvider } from "../BookContext";
import Loading from "../Loader/Loading";

export default function WrittenOffBooks() {
  const { books, getBooks } = useBookProvider();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      if (books.length <= 0) {
        setLoading(true);
        await getBooks();
        setLoading(false);
      }
    })();
  }, []);

  const tabItems = [
    {
      title: "All Written Off Books",
      index: 0,
      component: () => <AllWrittenOffBook />,
    },
    {
      title: "Book By Types",
      index: 1,
      component: () => <BookByTypes />,
    },
    {
      title: "View Report",
      index: 2,
      component: () => <ReportWrittenOffBooks />,
    },
  ];

  return <>{loading ? <Loading /> : <CustomTab tabs={tabItems} />}</>;
}
