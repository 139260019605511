const ebookFields = [
  {
    id: "path",
    numeric: false,
    disablePadding: false,
    label: "File",
    width: "auto",
    type: "file",
  },
  {
    id: "bookId",
    numeric: false,
    disablePadding: false,
    label: "Book Id",
    width: "auto",
  },
  {
    id: "title",
    numeric: false,
    disablePadding: false,
    label: "Title",
    width: "200px",
  },
  {
    id: "author",
    numeric: false,
    disablePadding: false,
    label: "Author",
    width: "200px",
  },
  {
    id: "type",
    numeric: false,
    disablePadding: false,
    label: "Type",
    width: "100px",
  },
  {
    id: "note",
    numeric: false,
    disablePadding: false,
    label: "note",
    width: "auto",
  },
];

export default ebookFields;
