import * as React from "react";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import CustomTable from "../CustomTable";
import { useBookProvider } from "../BookContext";
import * as actions from "../../constants/actions";
import { UserAPI } from "../../api";
import { userFields } from "../../models";
import { processAPIError } from "../../api/parent";

const EnhancedTableToolbar = (props) => {
  const { numOfRecords } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
      <Box>
        <Typography variant="subtitle1">
          All Librarians ({numOfRecords})
        </Typography>
      </Box>
    </Toolbar>
  );
};

export default function LibrarianList() {
  const { dispatch, users } = useBookProvider();
  const [rows, setRows] = React.useState([]);

  React.useEffect(() => {
    const librarians = users
      .filter((item) => item.librarian)
      .map((item) => ({ ...item, role_name: item?.role?.title }));
    setRows(librarians ? librarians : []);
  }, [users]);

  const handleRemove = async (id) => {
    try {
      dispatch({ type: actions.LOADING, payload: true });
      await UserAPI.removeUser(id);
      dispatch({ type: actions.REMOVE_USER, payload: id });
    } catch (error) {
      dispatch({ type: actions.ERROR, payload: processAPIError(error) });
    } finally {
      dispatch({ type: actions.LOADING, payload: false });
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <EnhancedTableToolbar numOfRecords={rows?.length} />
        <CustomTable
          headCells={userFields.teacher}
          rows={rows}
          handleRemove={handleRemove}
          isDelete={true}
          isEdit={false}
        />
      </Paper>
    </Box>
  );
}
